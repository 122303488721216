<template>
    <div class="not-found-wrapper">
      <div class="content-404">
        <div class="image-container">
          <img src="@common-assets/404.png" alt="Starry is having some very hard time">
        </div>
        <div class="text-group">
          <span class="text inter">J'ai</span>
          <span class="text bakbak">404</span>
          <span class="text inter">problèmes</span>
        </div>
        <div class="text-group">
          <span class="text inter">Mais ma salopette n'en est pas un !</span>
        </div>
        <div class="button-container">
          <ActionButton 
            label="Retour à l'accueil"
            buttonType="primary"
            @click="goToHome"
          />
        </div>
      </div>
    </div>
  </template>

<script>
import ActionButton from '@common-components/ActionButton.vue';

export default {
  name: 'NotFoundPage',
  components: {
    ActionButton,
  },
  methods: {
    goToHome() {
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.not-found-wrapper {
  background-image: url('~@common-assets/mixed-clouds-bg.png'), linear-gradient(#204B8D, #BFEAFF);
  background-size: 100%, cover;
  background-position: bottom, center;
  background-repeat: no-repeat, no-repeat;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.content-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.text-group {
  text-align: center;
}

.text-group:nth-child(2) {
  flex-direction: column;
}

.text {
  color: #FFF;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.25);
}

.inter {
  font-family: 'Inter', sans-serif;
  font-size: min(4vw, 20px);
}

.bakbak {
  font-family: 'Bakbak One', cursive;
  font-size: min(20vw, 100px);
  padding: 0 min(2.4vw, 12px);
}

.image-container img {
  width: min(300px, 60vw);
}

.button-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>