<template>
  <div id="app" class="container-fluid">
    <!-- Android detection toast -->
    <div v-if="showAndroidToast" class="android-toast" id="android-toast">
      📱 {{ $t('home.android-toast-message') }}
      <a :href="playStoreUrl" target="_blank" id="android-toast-link">
        {{ $t('home.android-toast-link') }}
      </a>
      <button @click="hideAndroidToast" id="android-toast-close">✖</button>
    </div>

    <!-- Lottie presentation code, depending on payment state -->
    <div class="main-content">
      <div class="menu-fixed menu-links" id="top-menu">
        <img
          class="lone-star"
          src="@website-assets/img/lone-star.png"
          alt="Lone Star"
          id="menu-logo-img"
        />
        <a href="/account/login" class="menu-link" id="top-login-link">
          {{ $i18n.t('global.connect') }}
        </a>
      </div>
      <!-- Header with title & video -->
      <header class="main-head" id="main-head">
        <div class="section-wrapper">
          <h1 class="header__logo" id="header-logo">
            <img
              src="@website-assets/img/logo-stowy.png"
              alt="Stowy"
              id="header-logo-img"
            />
          </h1>

          <h2 class="main-head__title" id="header-title">
            <Typed
              :options="typedOptions"
              :class="'kid-name'"
              id="typed-element"
            ></Typed>
            &nbsp;{{ $t('home.head-title') }}
          </h2>

          <h3 class="main-head__descr" id="header-description">
            <span>{{ $i18n.t('home.head-desc-1') }}</span>
            {{ $i18n.t('home.head-desc-2') }}
          </h3>

          <p class="main-head__callout-btns" id="header-callout-buttons">
            <a
              href="#stowy-plans"
              class="btn btn-primary btn-L"
              id="header-subscribe-btn"
            >
              <span>{{ $t('global.i-subscribe') }}</span>
            </a>

            <a
              v-if="!canInstall"
              href="#bubble-points"
              class="btn btn-secondary btn-L"
              id="header-discover-btn"
            >
              {{ $t('global.i-discover') }}
            </a>

            <a
              v-if="canInstall"
              @click="installApp"
              class="btn btn-secondary btn-L"
              id="header-try-btn"
            >
              {{ $t('global.try-stowy') }}
            </a>
          </p>

          <div class="starry" id="starry">
            <img src="@website-assets/img/starry.png" alt id="starry-img" />
          </div>
        </div>
      </header>

      <section class="menu" id="secondary-menu">
        <img
          src="@website-assets/img/logo-stowy.png"
          alt="Stowy Logo"
          class="menu-logo"
          id="secondary-menu-logo"
        />

        <div class="menu-links menu-flex" id="menu-links">
          <a
            href="#stowy-plans"
            class="menu-link subscribe-menu-btn"
            id="menu-inscription-btn"
          >
            Inscription
          </a>
          <div class="menu-fixed connect-menu-btn" id="menu-connect">
            <img
              class="lone-star"
              src="@website-assets/img/lone-star.png"
              alt="Lone Star"
              id="connect-lone-star"
            />
            <a href="/account/login" class="menu-link" id="menu-login-btn"
              >Connexion</a
            >
          </div>
        </div>
      </section>

      <!-- Points with bubbles & illustrations -->
      <section class="bubble-points" id="bubble-points">
        <h2 class="section-title" id="bubble-section-title">
          {{ $i18n.t('home.bubble-points-section-title') }}
        </h2>

        <article class="bubble-point bubble-point-kid" id="bubble-point-kid">
          <div class="bubble-point__wrapper">
            <blockquote class="bubble-point__main" id="blockquote-kid">
              <q>{{ $i18n.t('home.bubble-points-quoted-1') }}</q>
            </blockquote>
            <p class="bubble-point__secondary" id="bubble-secondary-kid">
              {{ $i18n.t('home.bubble-points-quoted-1-desc') }}
            </p>
          </div>
        </article>

        <article class="bubble-point bubble-point-mum" id="bubble-point-mum">
          <div class="bubble-point__wrapper">
            <blockquote class="bubble-point__main" id="blockquote-mum">
              <q>{{ $i18n.t('home.bubble-points-quoted-2') }}</q>
            </blockquote>
            <p class="bubble-point__secondary" id="bubble-secondary-mum">
              {{ $i18n.t('home.bubble-points-quoted-2-desc') }}
            </p>
          </div>
        </article>

        <article class="bubble-point bubble-point-lgbt" id="bubble-point-lgbt">
          <div class="bubble-point__wrapper">
            <blockquote class="bubble-point__main" id="blockquote-lgbt">
              <q>{{ $i18n.t('home.bubble-points-quoted-3') }}</q>
            </blockquote>
            <p class="bubble-point__secondary" id="bubble-secondary-lgbt">
              {{ $i18n.t('home.bubble-points-quoted-3-desc') }}
              <br />
              <strong>{{
                $i18n.t('home.bubble-points-quoted-3-addon')
              }}</strong>
            </p>
          </div>
        </article>

        <p class="bubble-point__callout-wrapper" id="bubble-callout-wrapper">
          <a
            href="#stowy-plans"
            class="btn btn-primary btn-L bubble-point__callout-btn"
            id="bubble-support-btn"
          >
            <span>{{ $i18n.t('global.support-us') }}</span>
          </a>
        </p>
      </section>

      <!-- 3 blocks with long scrolling visual -->
      <section class="how-why-who" id="how-why-who">
        <div class="section-wrapper" id="how-section-wrapper">
          <div class="how" id="how">
            <header class="how__head" id="how-head">
              <h2 class="section-title is-purple" id="how-title">
                {{ $i18n.t('home.how-why-1') }}
              </h2>
              <a
                href="#stowy-plans"
                class="btn-noBgd how__callout-btn"
                id="how-interested-btn"
              >
                {{ $i18n.t('global.im-interested') }}
              </a>
            </header>

            <div id="how__tabs">
              <ul class="how__tabs-nav" id="how-tabs-nav">
                <li>
                  <a href="#tabs-1" id="tab-nav-1">
                    <span class="how__tabs-nav-number">1</span>
                    <span class="how__tabs-nav-text">
                      {{ $i18n.t('home.how-why-2') }}
                    </span>
                  </a>
                </li>

                <li>
                  <a href="#tabs-2" id="tab-nav-2">
                    <span class="how__tabs-nav-number">2</span>
                    <span class="how__tabs-nav-text">
                      {{ $i18n.t('home.how-why-3') }}
                    </span>
                  </a>
                </li>

                <li>
                  <a href="#tabs-3" id="tab-nav-3">
                    <span class="how__tabs-nav-number">3</span>
                    <span class="how__tabs-nav-text">
                      {{ $i18n.t('home.how-why-4') }}
                    </span>
                  </a>
                </li>

                <li>
                  <a href="#tabs-4" id="tab-nav-4">
                    <span class="how__tabs-nav-number">4</span>
                    <span class="how__tabs-nav-text">
                      {{ $i18n.t('home.how-why-5') }}
                    </span>
                  </a>
                </li>
              </ul>

              <div id="tabs-1" class="how__tab how__tab-1">
                <img
                  class="how-tab__visual"
                  src="@website-assets/img/how__visual-1@2x.jpg"
                  alt
                  id="how-visual-1"
                />
              </div>

              <div id="tabs-2" class="how__tab how__tab-2">
                <img
                  class="how-tab__visual"
                  src="@website-assets/img/how__visual-2@2x.jpg"
                  alt
                  id="how-visual-2"
                />
              </div>

              <div id="tabs-3" class="how__tab how__tab-3">
                <img
                  class="how-tab__visual"
                  src="@website-assets/img/how__visual-3@2x.jpg"
                  alt
                  id="how-visual-3"
                />
              </div>

              <div id="tabs-4" class="how__tab how__tab-4">
                <img
                  class="how-tab__visual"
                  src="@website-assets/img/how__visual-4@2x.jpg"
                  alt
                  id="how-visual-4"
                />
              </div>

              <div class="clear" id="clear-tabs"></div>
            </div>

            <p class="how__callout-btn--mobile" id="how-mobile-callout">
              <a href="#" class="btn-noBgd" id="how-interested-mobile-btn">
                {{ $i18n.t('global.im-interested') }}
              </a>
            </p>
          </div>

          <div class="why" id="why">
            <h2
              class="section-title section-title--center is-orange"
              id="why-title"
            >
              {{ $i18n.t('home.why-trust') }}
            </h2>

            <ul class="why__list" id="why-list">
              <li class="why__point" id="why-point-1">
                <div class="rounded-image" id="why-rounded-1">
                  <img
                    src="@website-assets/img/pedagogique.png"
                    alt="Lettres pédagogiques"
                    id="why-img-1"
                  />
                </div>
                <h4 id="why-heading-1">
                  {{ $i18n.t('home.why-pedagogic-1') }}
                </h4>
                <p id="why-text-1">{{ $i18n.t('home.why-pedagogic-2') }}</p>
                <p id="why-text-1-2">{{ $i18n.t('home.why-pedagogic-3') }}</p>
              </li>

              <li class="why__point" id="why-point-2">
                <div class="rounded-image" id="why-rounded-2">
                  <img
                    src="@website-assets/img/familles.png"
                    alt="Famille"
                    id="why-img-2"
                  />
                </div>
                <h4 id="why-heading-2">{{ $i18n.t('home.why-family-1') }}</h4>
                <p id="why-text-2">{{ $i18n.t('home.why-family-2') }}</p>
                <p id="why-text-2-2">{{ $i18n.t('home.why-family-3') }}</p>
              </li>

              <li class="why__point" id="why-point-3">
                <div class="rounded-image" id="why-rounded-3">
                  <img
                    src="@website-assets/img/madeinfrance.png"
                    alt="Made in France"
                    id="why-img-3"
                  />
                </div>
                <h4 id="why-heading-3">{{ $i18n.t('home.why-mif-1') }}</h4>
                <p id="why-text-3">{{ $i18n.t('home.why-mif-2') }}</p>
                <p id="why-text-3-2">{{ $i18n.t('home.why-mif-3') }}</p>
              </li>
            </ul>

            <p class="why__callout-wrapper" id="why-callout-wrapper">
              <a
                href="#stowy-plans"
                class="btn-noBgd why__callout-btn"
                id="why-inscription-btn"
              >
                {{ $i18n.t('global.im-with-you') }}
              </a>
            </p>
          </div>
        </div>
      </section>

      <!-- Different plans to subscribe -->
      <section class="plans" id="stowy-plans">
        <div class="section-wrapper" id="plans-section-wrapper">
          <header class="plans__header" id="plans-header">
            <div class="subtitle" id="plans-subtitle">
              <h3 id="plans-title">
                {{ $i18n.t('home.roadmap-2') }}
                <strong id="plans-strong">{{
                  $i18n.t('home.roadmap-3')
                }}</strong>
                {{ $i18n.t('home.roadmap-4') }}
              </h3>
            </div>
            <div id="plans-header-divider"></div>
          </header>

          <div class="plans__list" id="plans-list">
            <!-- Free Plan -->
            <article
              id="plan-beta-tester"
              class="plan plan-purple"
              v-on:click="onSelect(0)"
            >
              <div class="plan__wrapper" id="plan-beta-wrapper">
                <div class="plan__price" id="plan-beta-price">
                  {{ $i18n.t('home.plan-free-1') }}
                </div>
                <div class="plan__price-wrapper" id="plan-beta-price-wrapper">
                  <h3 class="plan__title" id="plan-beta-title">
                    {{ $i18n.t('home.plan-free-5') }}
                  </h3>
                </div>
                <ul class="plan__offer" id="plan-beta-offer">
                  <li id="plan-beta-offer-1">
                    {{ $i18n.t('home.plan-free-2') }}
                  </li>
                  <li class="not-included" id="plan-beta-offer-2">
                    {{ $i18n.t('home.plan-free-3') }}
                  </li>
                  <li class="not-included" id="plan-beta-offer-3">
                    {{ $i18n.t('home.plan-free-4') }}
                  </li>
                </ul>
                <footer class="choose-plan-btn" id="plan-beta-footer">
                  <a
                    href="/account/register?plan=free"
                    class="btn btn-secondary btn-L"
                    id="plan-free-btn"
                  >
                    <span>{{ $i18n.t('global.choose-formula') }}</span>
                  </a>
                </footer>
              </div>
            </article>

            <!-- Plan Solo -->
            <article
              id="plan-solo"
              class="plan plan-blue"
              v-on:click="onSelect(1)"
            >
              <div class="plan__wrapper" id="plan-solo-wrapper">
                <div class="plan__price" id="plan-solo-price">
                  {{ $i18n.t('home.plan-solo-1') }}
                </div>
                <div class="plan__price-wrapper" id="plan-solo-price-wrapper">
                  <h3 class="plan__title" id="plan-solo-title">
                    {{ $i18n.t('home.plan-solo-5') }}
                  </h3>
                  <div class="plan__price-info" id="plan-solo-info-1">
                    {{ $i18n.t('home.plan-solo-6') }}
                  </div>
                  <div class="plan__price-info" id="plan-solo-info-2">
                    {{ $i18n.t('home.billedMensually') }}
                  </div>
                </div>
                <ul class="plan__offer" id="plan-solo-offer">
                  <li id="plan-solo-offer-1">
                    {{ $i18n.t('home.plan-solo-2') }}
                  </li>
                  <li id="plan-solo-offer-2">
                    {{ $i18n.t('home.plan-solo-3') }}
                  </li>
                  <li class="not-included" id="plan-solo-offer-3">
                    {{ $i18n.t('home.plan-solo-4') }}
                  </li>
                </ul>
                <footer class="choose-plan-btn" id="plan-solo-footer">
                  <a
                    href="/account/register?plan=solo"
                    class="btn btn-primary btn-L"
                    id="plan-solo-btn"
                  >
                    <span>{{ $i18n.t('global.choose-formula') }}</span>
                  </a>
                </footer>
              </div>
            </article>

            <!-- Multi kids -->
            <article
              id="plan-family"
              class="plan plan-orange"
              v-on:click="onSelect(2)"
            >
              <div class="plan__wrapper" id="plan-family-wrapper">
                <div class="plan__price" id="plan-family-price">
                  {{ $i18n.t('home.plan-family-1') }}
                </div>
                <div class="plan__price-wrapper" id="plan-family-price-wrapper">
                  <h3 class="plan__title" id="plan-family-title">
                    {{ $i18n.t('home.plan-family-5') }}
                  </h3>
                  <div class="plan__price-info" id="plan-family-info-1">
                    {{ $i18n.t('home.plan-solo-6') }}
                  </div>
                  <div class="plan__price-info" id="plan-family-info-2">
                    {{ $i18n.t('home.billedMensually') }}
                  </div>
                </div>
                <ul class="plan__offer" id="plan-family-offer">
                  <li id="plan-family-offer-1">
                    {{ $i18n.t('home.plan-family-2') }}
                  </li>
                  <li id="plan-family-offer-2">
                    {{ $i18n.t('home.plan-family-3') }}
                  </li>
                  <li id="plan-family-offer-3">
                    {{ $i18n.t('home.plan-family-4') }}
                  </li>
                </ul>
                <footer class="choose-plan-btn" id="plan-family-footer">
                  <a
                    href="/account/register?plan=multi"
                    class="btn btn-secondary-orange btn-L"
                    id="plan-multi-btn"
                  >
                    <span>{{ $i18n.t('global.choose-formula') }}</span>
                  </a>
                </footer>
              </div>
            </article>
          </div>
          <div
            style="height: 26px"
            class="error-message"
            id="plans-error-message"
          >
            {{ userSession.error || '' }}
          </div>
        </div>
      </section>

      <footer class="stowy-footer" id="footer">
        <div class="section-wrapper" id="footer-section-wrapper">
          <ul class="footer__left" id="footer-left">
            <li id="footer-legals-li">
              <router-link
                :to="{ name: 'Legals' }"
                target="_blank"
                id="footer-legals-link"
              >
                {{ $i18n.t('global.legals-term') }}
              </router-link>
            </li>
            <li id="footer-cgu-li">
              <router-link
                :to="{ name: 'Cgu' }"
                target="_blank"
                id="footer-cgu-link"
              >
                {{ $i18n.t('global.cgv-cgu-term') }}
              </router-link>
            </li>
            <li id="footer-confidentiality-li">
              <router-link
                :to="{ name: 'Confidentiality' }"
                target="_blank"
                id="footer-confidentiality-link"
              >
                {{ $i18n.t('global.confidentiality-term') }}
              </router-link>
            </li>
            <li id="footer-contact-li">
              <a href="mailto:kevin@stowy.fr" id="footer-contact-link">
                {{ $i18n.t('home.contact-address') }}
              </a>
            </li>
          </ul>

          <div class="footer__center" id="footer-center">
            <img
              class="footer__logo"
              src="@website-assets/img/logo-stowy.png"
              alt="Stowy"
              id="footer-logo-img"
            />
            <p class="footer__social" id="footer-social">
              <a
                href="https://www.facebook.com/StowyFR"
                target="_blank"
                class="footer__social-btn footer__facebook"
                id="footer-facebook-link"
              ></a>

              <a
                href="https://www.twitter.com/StowyFR"
                target="_blank"
                class="footer__social-btn footer__twitter"
                id="footer-twitter-link"
              ></a>
              <a
                href="https://www.instagram.com/StowyFR"
                target="_blank"
                class="footer__social-btn footer__insta"
                id="footer-instagram-link"
              ></a>
            </p>
          </div>

          <p class="footer__copyright" id="footer-copyright">
            {{ $i18n.t('home.copyright-title') }}
            <br />
            {{ $i18n.t('home.copyright-1') }}
            <br />
            {{ $i18n.t('home.copyright-2') }}
          </p>
        </div>
      </footer>

      <!-- Cookies Section -->
      <div id="cookies" class="cookies" v-show="!userSession.cookies.acted">
        <div class="cookies__wrapper">
          <h3 class="cookies__title" id="cookies-title">
            {{ $i18n.t('home.cookies-title') }}
          </h3>
          <p class="cookies__intro" id="cookies-intro">
            {{ $i18n.t('home.cookies-intro') }}
          </p>

          <div class="cookies--short" v-if="!isExpanded">
            <ul v-if="false" class="cookies-short__options">
              <li>
                <input
                  type="checkbox"
                  checked
                  disabled
                  v-model="userSession.cookies.essentials"
                  id="short-essential-checkbox"
                />
                <label id="short-essential-label">{{
                  $i18n.t('home.cookies-option-1')
                }}</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked
                  v-model="userSession.cookies.analytics"
                  id="short-analytics-checkbox"
                />
                <label id="short-analytics-label">{{
                  $i18n.t('home.cookies-option-2')
                }}</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked
                  v-model="userSession.cookies.ads"
                  id="short-ads-checkbox"
                />
                <label id="short-ads-label">{{
                  $i18n.t('home.cookies-option-3')
                }}</label>
              </li>
            </ul>

            <div class="cookies__call-btns" id="cookies-short-buttons">
              <a
                class="btn btn-secondary"
                v-on:click="onExpandCookies()"
                id="cookies-configure-btn"
              >
                <span>{{ $i18n.t('home.cookies-button-configure') }}</span>
              </a>
              <a
                class="btn btn-secondary"
                v-on:click="onSaveCookies(true, false, false)"
                id="cookies-refuse-btn"
              >
                <span>{{ $i18n.t('home.cookies-button-refuse') }}</span>
              </a>
              <a
                class="btn btn-primary"
                v-on:click="onSaveCookies(true, true, true)"
                id="cookies-accept-btn"
              >
                <span>{{ $i18n.t('home.cookies-button-accept') }}</span>
              </a>
            </div>
          </div>

          <div class="cookies--long" v-show="isExpanded" id="cookies-long">
            <!-- Section des cookies Essentiels -->
            <div class="cookie-section" id="cookie-section-essentials">
              <div class="cookie-option" id="cookie-option-essentials">
                <input
                  class="cookies--long-checkbox"
                  type="checkbox"
                  id="long-essential-checkbox"
                  checked
                  disabled
                  v-model="userSession.cookies.essentials"
                />
                <label for="essential" id="long-essential-label">
                  <h4>{{ $i18n.t('home.cookies-option-1') }}</h4>
                </label>
              </div>
              <ul class="cookie-list" id="cookie-list-essentials">
                <li id="essential-description">
                  {{ $i18n.t('home.cookies.essential.description') }}
                </li>
                <li id="essential-session">
                  <strong id="essential-session-label">
                    {{ $i18n.t('home.cookies.essential.list.session.name') }}:
                  </strong>
                  {{
                    $i18n.t('home.cookies.essential.list.session.description')
                  }}.
                  <br />
                  {{ $i18n.t('home.cookies.essential.list.session.data') }}
                  <br />
                  {{ $i18n.t('home.cookies.essential.list.session.duration') }}
                </li>
                <li id="essential-consent">
                  <strong id="essential-consent-label">
                    {{ $i18n.t('home.cookies.essential.list.consent.name') }}:
                  </strong>
                  {{
                    $i18n.t('home.cookies.essential.list.consent.description')
                  }}.
                  <br />
                  {{ $i18n.t('home.cookies.essential.list.consent.data') }}
                  <br />
                  {{ $i18n.t('home.cookies.essential.list.consent.duration') }}
                </li>
                <li id="essential-app">
                  <strong id="essential-app-label">
                    {{ $i18n.t('home.cookies.essential.list.app.name') }}:
                  </strong>
                  {{ $i18n.t('home.cookies.essential.list.app.description') }}.
                  <br />
                  {{ $i18n.t('home.cookies.essential.list.app.data') }}
                  <br />
                  {{ $i18n.t('home.cookies.essential.list.app.duration') }}
                </li>
              </ul>
            </div>

            <!-- Section des cookies Analytiques -->
            <div class="cookie-section" id="cookie-section-analytics">
              <div class="cookie-option" id="cookie-option-analytics">
                <input
                  class="cookies--long-checkbox"
                  type="checkbox"
                  id="long-analytics-checkbox"
                  checked
                  v-model="userSession.cookies.analytics"
                />
                <label for="analytics" id="long-analytics-label">
                  <h4>{{ $i18n.t('home.cookies-option-2') }}</h4>
                </label>
              </div>
              <ul class="cookie-list" id="cookie-list-analytics">
                <li id="analytics-description">
                  {{ $i18n.t('home.cookies.analytics.description') }}
                </li>
                <li id="analytics-ga">
                  <strong id="analytics-ga-label">
                    {{ $i18n.t('home.cookies.analytics.list._ga.name') }}:
                  </strong>
                  {{ $i18n.t('home.cookies.analytics.list._ga.description') }}.
                  <br />
                  {{ $i18n.t('home.cookies.analytics.list._ga.data') }}
                  <br />
                  {{ $i18n.t('home.cookies.analytics.list._ga.duration') }}
                </li>
                <li id="analytics-gid">
                  <strong id="analytics-gid-label">
                    {{ $i18n.t('home.cookies.analytics.list._gid.name') }}:
                  </strong>
                  {{ $i18n.t('home.cookies.analytics.list._gid.description') }}.
                  <br />
                  {{ $i18n.t('home.cookies.analytics.list._gid.data') }}
                  <br />
                  {{ $i18n.t('home.cookies.analytics.list._gid.duration') }}
                </li>
                <li id="analytics-gat">
                  <strong id="analytics-gat-label">
                    {{ $i18n.t('home.cookies.analytics.list._gat.name') }}:
                  </strong>
                  {{ $i18n.t('home.cookies.analytics.list._gat.description') }}.
                  <br />
                  {{ $i18n.t('home.cookies.analytics.list._gat.data') }}
                  <br />
                  {{ $i18n.t('home.cookies.analytics.list._gat.duration') }}
                </li>
              </ul>
            </div>

            <!-- Section des cookies Publicitaires -->
            <div class="cookie-section" id="cookie-section-ads">
              <div class="cookie-option" id="cookie-option-ads">
                <input
                  class="cookies--long-checkbox"
                  type="checkbox"
                  id="long-ads-checkbox"
                  v-model="userSession.cookies.ads"
                />
                <label for="ads" id="long-ads-label">
                  <h4>{{ $i18n.t('home.cookies-option-3') }}</h4>
                </label>
              </div>
              <ul class="cookie-list" id="cookie-list-ads">
                <li id="ads-description">
                  {{ $i18n.t('home.cookies.advertising.description') }}
                </li>
                <li id="ads-gcl">
                  <strong id="ads-gcl-label">
                    {{ $i18n.t('home.cookies.advertising.list._gcl_au.name') }}:
                  </strong>
                  {{
                    $i18n.t(
                      'home.cookies.advertising.list._gcl_au.description'
                    )
                  }}.
                  <br />
                  {{ $i18n.t('home.cookies.advertising.list._gcl_au.data') }}
                  <br />
                  {{
                    $i18n.t('home.cookies.advertising.list._gcl_au.duration')
                  }}
                </li>
              </ul>
            </div>
            <div class="cookies__call-btns" id="cookies-long-buttons">
              <a
                class="btn btn-secondary"
                v-on:click="onSaveCookies(true, true, true)"
                id="cookies-accept-all-btn"
              >
                <span>{{ $i18n.t('home.cookies-button-accept-all') }}</span>
              </a>
              <a
                class="btn btn-primary"
                v-on:click="
                  onSaveCookies(
                    userSession.cookies.essentials,
                    userSession.cookies.analytics,
                    userSession.cookies.ads
                  )
                "
                id="cookies-accept-selection-btn"
              >
                <span>{{
                  $i18n.t('home.cookies-button-accept-selection')
                }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Adjust cookie-trigger vertical position when Android toast is present -->
    <div
      v-show="userSession.cookies.acted"
      class="cookie-trigger"
      id="cookie-trigger"
      :style="{ bottom: showAndroidToast ? '100px' : '20px' }"
      @click="onResetCookies"
    >
      <span class="cookie-emoji" id="cookie-trigger-emoji">🍪</span>
    </div>

    <locale-switcher v-if="isDev" id="locale-switcher" />
    <info-popup ref="infoPopup" id="info-popup"></info-popup>
  </div>
</template>
<script>
  import InfoPopup from '../components_prelaunch/InfoPopup.vue'
  import LocaleSwitcher from '../components_prelaunch/LocaleSwitcher.vue'
  import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
  import Typed from '@common-components/Typed.vue'
  import { useHead } from '@unhead/vue'
  import { mapState } from 'vuex'

  export default {
    name: 'Home',
    components: {
      InfoPopup,
      LocaleSwitcher,
      VueCookieAcceptDecline,
      Typed,
    },

    setup() {
      useHead({
        title: 'Stowy - Histoires personnalisées pour enfants',
        meta: [
          {
            name: 'description',
            content:
              'Stowy propose des histoires personnalisées pour enfants, créez des souvenirs uniques en quelques clics.',
          },
          {
            name: 'keywords',
            content:
              'histoires personnalisées, enfants, souvenirs, lecture, éducation, divertissement, Stowy',
          },
          {
            property: 'og:title',
            content: 'Stowy - Histoires personnalisées pour enfants',
          },
          {
            property: 'og:description',
            content:
              'Créez des souvenirs uniques avec des histoires personnalisées pour vos enfants grâce à Stowy.',
          },
          {
            property: 'og:image',
            content: 'https://stowy.fr/assets/img/stowy-og-image.jpg',
          },
          { property: 'og:url', content: 'https://stowy.fr' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Stowy' },
          { name: 'twitter:card', content: 'summary_large_image' },
          {
            name: 'twitter:title',
            content: 'Stowy - Histoires personnalisées pour enfants',
          },
          {
            name: 'twitter:description',
            content:
              'Découvrez Stowy, l’application pour créer des histoires personnalisées pour enfants et partager des moments inoubliables.',
          },
          {
            name: 'twitter:image',
            content: 'https://stowy.fr/assets/img/stowy-twitter-card.jpg',
          },
          { name: 'twitter:site', content: '@StowyFR' },
          { name: 'author', content: 'Stowy' },
          { name: 'language', content: 'fr' },
          { name: 'robots', content: 'index, follow' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          { charset: 'utf-8' },
        ],
        link: [{ rel: 'canonical', href: 'https://stowy.fr' }],
      })
    },

    data() {
      return {
        options: ['free', 'solo', 'family'],
        surname: [
          'surname-1',
          'surname-2',
          'surname-3',
          'surname-4',
          'surname-5',
          'surname-6',
          'surname-7',
          'surname-8',
        ],
        selectedOptionIndex: null,
        isCgvChecked: false,
        errors: [],
        isExpanded: false,
        deferredPrompt: null,
        canInstall: false,
        // Variables for Android Toast
        showAndroidToast: false,
        playStoreUrl:
          'https://play.google.com/store/apps/details?id=com.stowy.android.production.release',
      }
    },
    computed: {
      ...mapState(['userSession']),
      typedOptions() {
        return {
          strings: this.surname.map((name) => this.$t('home.' + name)),
          typeSpeed: 100,
          deleteSpeed: 100,
          autoDelete: true,
          loop: true,
        }
      },
      isDev() {
        return process.env.NODE_ENV === 'development'
      },
      isEmailValid() {
        return (
          this.userSession.profile &&
          this.userSession.profile.email &&
          this.validateEmail(this.userSession.profile.email)
        )
      },
      isFirstNameValid() {
        return (
          this.userSession.profile &&
          this.userSession.profile.firstName &&
          this.validateName(this.userSession.profile.firstName)
        )
      },
      isLastNameValid() {
        return (
          this.userSession.profile &&
          this.userSession.profile.lastName &&
          this.validateName(this.userSession.profile.lastName)
        )
      },
      shouldEnableRegisterButton() {
        return !(
          this.userSession.auth.signingIn ||
          this.userSession.payment.awaiting ||
          this.userSession.payment.pending
        )
      },
      defaultSelectedOptionIndex() {
        if (this.selectedOptionIndex != null) {
          return this.selectedOptionIndex
        } else if (
          this.userSession.profile &&
          this.userSession.profile.stripeRole
        ) {
          return this.userSession.profile.stripeRole === 'free'
            ? 0
            : this.userSession.profile.stripeRole === 'solo'
              ? 1
              : 2
        }
        return 1
      },
    },
    updated() {
      this.onSelect(this.defaultSelectedOptionIndex)
    },
    mounted() {
      // Scroll event listener
      window.addEventListener('scroll', this.handleScroll)

      // PWA events
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        console.debug('beforeinstallprompt fired')
        this.deferredPrompt = e
        this.canInstall = true
      })

      window.addEventListener('appinstalled', () => {
        console.debug('PWA a été installée')
        this.canInstall = false
        this.deferredPrompt = null
      })

      // Cookies initialization
      this.userSession.cookies.essentials =
        JSON.parse(localStorage.getItem('cookies.essentials')) || true
      this.userSession.cookies.analytics =
        JSON.parse(localStorage.getItem('cookies.analytics')) || false
      this.userSession.cookies.ads =
        JSON.parse(localStorage.getItem('cookies.ads')) || false
      this.userSession.cookies.acted =
        JSON.parse(localStorage.getItem('cookies.acted')) || false

      if (this.userSession.cookies.acted) {
        this.isExpanded = false
      }

      if (
        this.userSession.cookies.acted &&
        (this.userSession.cookies.ads || this.userSession.cookies.analytics)
      ) {
        this.$store.dispatch('initializeAnalytics')
      }

      // Remove jQuery UI tabs implementation and initialize vanilla JS tabs instead
      this.setupTabs()

      // Android detection toast
      this.detectAndroid()
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      installApp() {
        if (this.deferredPrompt) {
          this.deferredPrompt.prompt() // Trigger installation modal
          this.deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt')
            } else {
              console.log('User dismissed the A2HS prompt')
            }
            this.deferredPrompt = null // Reset prompt
            this.canInstall = false
          })
        }
      },
      removeGoogleTagManager() {
        const gid = process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
        const gtmId = 'GTM-PMGW38M' // Remplace par ton vrai ID GTM si besoin

        // Supprimer le script GTM dans le <head>
        const script = document.querySelector(
          `script[src*="googletagmanager.com/gtm.js?id=${gtmId}"]`
        )
        if (script) {
          script.parentNode.removeChild(script)
        }

        // Supprimer le script de Google Analytics (gtag) injecté par Firebase si présent
        const analyticsScript = document.querySelector(
          `script[src*="googletagmanager.com/gtag/js?id=${gid}"]`
        )
        if (analyticsScript) {
          analyticsScript.parentNode.removeChild(analyticsScript)
        }

        // Supprimer l'iframe GTM dans le <noscript> du <body>
        const gtmIframe = document.querySelector(
          `noscript iframe[src*="googletagmanager.com/ns.html?id=${gtmId}"]`
        )
        if (gtmIframe && gtmIframe.parentNode) {
          gtmIframe.parentNode.removeChild(gtmIframe)
        }

        // Réinitialiser dataLayer pour éviter l'envoi d'événements GTM
        if (window.dataLayer) {
          window.dataLayer = []
        }

        console.log('Google Tag Manager et Google Analytics ont été supprimés.')
      },
      handleScroll() {
        const bubblePoints = document.querySelector('#bubble-points')
        const menu = document.querySelector('.menu')
        const bubbleTop =
          bubblePoints.getBoundingClientRect().top + window.scrollY
        const menuHeight = window.innerWidth < 768 ? '50px' : '75px'
        if (window.scrollY >= bubbleTop - 200) {
          menu.style.height = menuHeight
        } else {
          menu.style.height = '0'
        }
      },
      validateName(inputText) {
        var format = /^[a-zA-Z]+/
        return inputText.match(format)
      },
      validateEmail(inputText) {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        return inputText.match(mailformat)
      },
      onSaveCookies(essentials, analytics, ads) {
        localStorage.setItem('cookies.essentials', JSON.stringify(essentials))
        localStorage.setItem('cookies.analytics', JSON.stringify(analytics))
        localStorage.setItem('cookies.ads', JSON.stringify(ads))
        localStorage.setItem('cookies.acted', JSON.stringify(true))
        this.userSession.cookies.essentials = essentials
        this.userSession.cookies.analytics = analytics
        this.userSession.cookies.ads = ads
        this.userSession.cookies.acted = true
        if (ads || analytics) {
          this.$store.dispatch('initializeAnalytics')
        } else {
          this.$store.dispatch('removeAnalytics')
        }
        this.onCookiesAccepted()
      },
      onCookiesAccepted() {
        document.getElementById('cookies').classList.remove('is-expanded')
        document.getElementById('cookies').classList.add('is-accepted')
      },
      onExpandCookies() {
        this.isExpanded = true
        const cookieElement = document.getElementById('cookies')
        if (cookieElement) {
          cookieElement.classList.add('is-expanded')
        } else {
          console.warn("Element 'cookies' not found")
        }
      },
      onResetCookies() {
        this.$store.commit('resetCookies')
        localStorage.setItem('cookies.essentials', JSON.stringify(true))
        localStorage.setItem('cookies.analytics', JSON.stringify(false))
        localStorage.setItem('cookies.ads', JSON.stringify(false))
        localStorage.setItem('cookies.acted', JSON.stringify(false))
        this.userSession.cookies.acted = false
        this.isExpanded = false
        document.getElementById('cookies').classList.remove('is-accepted')
      },
      onSelect(optionIndex) {
        try {
          this.selectedOptionIndex = optionIndex
          const freeClass = `plan plan-purple ${optionIndex === 0 ? 'is-active' : ''}`
          const soloClass = `plan plan-blue ${optionIndex === 1 ? 'is-active' : ''}`
          const familyClass = `plan plan-orange ${optionIndex === 2 ? 'is-active' : ''}`
          document.getElementById('plan-beta-tester').className = freeClass
          document.getElementById('plan-solo').className = soloClass
          document.getElementById('plan-family').className = familyClass
        } catch (error) {}
      },
      async proceed() {
        if (!this.isCgvChecked) {
          this.$store.dispatch('formError', this.$i18n.t('errors.cgv-check'))
        } else if (!this.shouldEnableRegisterButton) {
          return
        } else if (!this.isEmailValid) {
          this.$store.dispatch(
            'formError',
            this.$i18n.t('errors.invalid-email')
          )
        } else if (!this.isFirstNameValid) {
          this.$store.dispatch(
            'formError',
            this.$i18n.t('errors.invalid-firstname')
          )
        } else if (!this.isLastNameValid) {
          this.$store.dispatch(
            'formError',
            this.$i18n.t('errors.invalid-lastname')
          )
        } else {
          await this.$store.dispatch('formError', null)
          try {
            this.showRegisteringOngoing()
            const option = this.options[this.selectedOptionIndex]
            const signedUser = await this.$store.dispatch('SignUp', {
              email: this.userSession.profile.email,
              firstName: this.userSession.profile.firstName,
              lastName: this.userSession.profile.lastName,
              option: option,
            })
            if (option !== 'free') {
              this.showCheckoutOngoing()
            }
            const url = await this.$store.dispatch('checkout', {
              user: signedUser,
              option: option,
            })
            if (url && url.length) {
              this.showCheckoutPending(async () => {
                window.open(url, '_blank')
                this.showCheckoutOngoing()
                await this.$store.dispatch('paymentWaiting', {
                  user: signedUser,
                  url: url,
                })
                this.showRegisteringDone()
              })
            } else {
              this.showRegisteringDone()
            }
          } catch (error) {
            this.showErrorMessage(error)
            await this.$store.dispatch('formError', error)
          }
        }
      },
      async showRegisteringDone() {
        const p = await this.$refs.infoPopup.show({
          style: 'success',
          showAnimation: true,
          animated: true,
          message: this.$i18n.t('home.registering-done'),
          button: this.$i18n.t('global.close'),
          isButtonEnabled: true,
        })
      },
      async showRegisteringOngoing() {
        const p = await this.$refs.infoPopup.show({
          message: this.$i18n.t('home.registering-ongoing'),
          style: 'loading',
          showAnimation: true,
          animated: true,
          button: this.$i18n.t('global.continue'),
          isButtonEnabled: false,
        })
      },
      async showCheckoutPending(callback) {
        const p = await this.$refs.infoPopup.show({
          message: this.$i18n.t('home.checkout-pending'),
          style: 'info',
          showAnimation: true,
          animated: true,
          button: this.$i18n.t('global.continue'),
          buttonCallback: callback,
          isButtonEnabled: true,
        })
      },
      async showCheckoutOngoing() {
        const p = await this.$refs.infoPopup.show({
          message: this.$i18n.t('home.checkout-ongoing'),
          style: 'loading',
          showAnimation: true,
          animated: true,
          button: this.$i18n.t('global.continue'),
          isButtonEnabled: false,
        })
      },
      async showErrorMessage(message) {
        const p = await this.$refs.infoPopup.show({
          message: message,
          style: 'error',
          showAnimation: true,
          animated: false,
          button: this.$i18n.t('global.close'),
          isButtonEnabled: true,
        })
      },
      // Vanilla JS tabs setup without jQuery
      setupTabs() {
        const tabNavLinks = document.querySelectorAll(
          '#how__tabs .how__tabs-nav a'
        )
        const tabContents = document.querySelectorAll('#how__tabs .how__tab')

        tabContents.forEach((tab, index) => {
          if (index === 0) {
            tab.style.display = 'block'
            tab.style.opacity = '1'
          } else {
            tab.style.display = 'none'
            tab.style.opacity = '0'
          }
        })

        tabNavLinks.forEach((link) => {
          link.addEventListener('click', (e) => {
            e.preventDefault()
            const targetId = link.getAttribute('href')
            tabContents.forEach((tab) => {
              if ('#' + tab.id === targetId) {
                tab.style.display = 'block'
                tab.style.opacity = '1'
              } else {
                tab.style.display = 'none'
                tab.style.opacity = '0'
              }
            })
            tabNavLinks.forEach((nav) => {
              nav.parentElement.classList.remove('active')
            })
            link.parentElement.classList.add('active')
          })
        })
      },
      // New methods for Android detection toast
      detectAndroid() {
        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera
        if (/android/i.test(userAgent)) {
          if (!localStorage.getItem('hideAndroidToast')) {
            this.showAndroidToast = true
          }
        }
      },
      hideAndroidToast() {
        this.showAndroidToast = false
        localStorage.setItem('hideAndroidToast', 'true')
      },
    },
  }
</script>

<style scoped>
  .android-toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 1000;
  }
  .android-toast a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
  }
  .android-toast button {
    background: transparent;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }

  /* Responsive adjustments for tablet landscape and above */
  @media (max-width: 768px) {
    .android-toast {
      left: 50%;
      transform: transformX(-50%);
      width: 90%;
      justify-content: center;
    }
  }

  /* Example style for cookie-trigger (if not already defined) */
  .cookie-trigger {
    position: fixed;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    /* other styling as needed */
  }
</style>
