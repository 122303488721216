<template>
  <div class="cgu">
    <!-- CGU -->
    <div class="container page-main">
      <h1>{{ messageForKey('cgu.title') }}</h1>
      <div class="content">
        <div v-for="cguKey in allCguKeys" :key="cguKey">
          <div v-if="styleForKey(cguKey) === paragraphStyle">
            <p>{{ messageForKey(`${cguKey}`) }}</p>
          </div>
          <div v-else-if="styleForKey(cguKey) === subtitleStyle">
            <h2>{{ messageForKey(`${cguKey}`) }}</h2>
          </div>
          <div v-else-if="styleForKey(cguKey) === titleStyle">
            <h1>{{ messageForKey(`${cguKey}`) }}</h1>
          </div>
        </div>
      </div>
      <!-- CGV -->
      <h1>{{ messageForKey('cgv.title') }}</h1>
      <div class="content">
        <div v-for="cgvKey in allCgvKeys" :key="cgvKey">
          <div v-if="styleForKey(cgvKey) === paragraphStyle">
            <p>{{ messageForKey(`${cgvKey}`) }}</p>
          </div>
          <div v-else-if="styleForKey(cgvKey) === subtitleStyle">
            <h2>{{ messageForKey(`${cgvKey}`) }}</h2>
          </div>
          <div v-else-if="styleForKey(cgvKey) === titleStyle">
            <h1>{{ messageForKey(`${cgvKey}`) }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titleStyle: "h2",
      subtitleStyle: "h2",
      paragraphStyle: "p"
    };
  },
  computed: {
    allCguKeys() {
      var ks = Object.keys(this.$i18n.messages.en.cgu).map(k => `cgu.${k}`);
      ks.shift();
      return ks;
    },
    allCgvKeys() {
      var ks = Object.keys(this.$i18n.messages.en.cgv).map(k => `cgv.${k}`);
      ks.shift();
      return ks;
    }
  },
  methods: {
    messageForKey(key) {
      return this.$i18n.t(key);
    },
    styleForKey(key) {
      if (typeof key !== "string") {
        return;
      }

      if (key.endsWith("subtitle")) {
        return this.subtitleStyle;
      } else if (key.endsWith("title")) {
        return this.titleStyle;
      }
      return this.paragraphStyle;
    }
  }
};
</script>

<style lang="scss" scoped>
.cgu {

  background-image: url('~@common-assets/mixed-clouds-bg.png'), linear-gradient(#204B8D, #BFEAFF);
  background-size: 100%, cover;
  background-position: bottom, center;
  background-repeat: no-repeat, no-repeat;
  min-height: 100vh;
  margin: 0;
  padding: 0;

  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 36px 20px;
  }

  h1, h2 {
    color: #3b3f4a;
    font-family: "Baloo 2", sans-serif;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-top: 40px;
    line-height: 1.3;
  }

  h2 {
    font-size: 28px;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: #4a4a4a;
    margin-bottom: 20px;
    text-align: justify;
  }

  .content {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .page-main {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 36px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
}
</style>
