import { createApp } from 'vue'
import App from './AppPrelaunch.vue'
import router from './routes'
import store from '@common/store'
import '@common/logger'
import { auth, usersCollection } from '@common/firebase'
import i18n from './i18n'
import { createHead } from '@unhead/vue'

const init = async () => {
  let appInstance

  await auth.signInAnonymously()
  const head = createHead()

  auth.onAuthStateChanged((user) => {
    if (user && user.uid) {
      store.commit('setAuthUser', user)

      usersCollection.doc(user.uid).onSnapshot((snapshot) => {
        console.log(
          'Build:',
          process.env.NODE_ENV === 'production' ? 'production' : 'development'
        )

        if (!appInstance) {
          // Create and mount the Vue 3 application
          appInstance = createApp(App)
            .use(router)
            .use(store)
            .use(i18n)
            //Unhead plugin for SEO on SPA
            .use(head)
            .mount('#app')
        }

        if (snapshot.data()) {
          store.commit('setUserProfile', snapshot.data())
        }
      })
    }
  })
}

init()
