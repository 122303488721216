import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';
import Oops from './views/Oops.vue';
import Cgu from './views/Cgu.vue';
import Legals from './views/Legals.vue';
import Confidentiality from './views/Confidentiality.vue';
import FourOFour from './views/404.vue';

import { auth } from '@common/firebase';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/oops',
    name: 'Oops',
    component: Oops
  },
  {
    path: '/cgu-cgv',
    name: 'Cgu',
    component: Cgu
  },
  {
    path: '/legals',
    name: 'Legals',
    component: Legals
  },
  {
    path: '/confidentiality',
    name: 'Confidentiality',
    component: Confidentiality
  },
  {
    path: '/404',
    name: '404',
    component: FourOFour,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'CatchAll',
    beforeEnter: (to, from, next) => {
      // Prevent an infinite loop by checking if we're already on the 404 page
      if (to.name !== '404') {
        next({ name: '404' });
      } else {
        next(); // Allow navigation to 404 page
      }
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation Guards
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresGuest = to.matched.some(record => record.meta.requiresGuest);

  if (requiresAuth && !auth.currentUser) {
    next('/');
  } else if (requiresGuest && auth.currentUser) {
    next('/');
  } else {
    next();
  }
});

export default router;
