export default {
    "home": {
      "head-title": " can't believe their eyes!",
      "head-desc-1": "Dozens of personalized stories where your children are the heroes,",
      "head-desc-2": "always at hand, to read as a family like a book. Discover Stowy now!",
      "bubble-points-section-title": "With Stowy, bedtime stories become a sacred and unforgettable family moment",
      "bubble-points-quoted-1": "I am the hero of my own stories!",
      "bubble-points-quoted-1-desc": "Regularly, new original and customizable stories are added to experience immersive and thrilling moments as a family.",
      "bubble-points-quoted-2": "With Stowy, finding a moment to read a story is no longer a headache!",
      "bubble-points-quoted-2-desc": "Dozens of stories at your fingertips, accessible in a few clicks. No matter how much time you have, the age of your children, or the time of day, we have the perfect story for you!",
      "bubble-points-quoted-3": "All families are in Stowy!",
      "bubble-points-quoted-3-desc": "Thanks to its customization system, Stowy strengthens the bond between family members. Every evening then becomes a great moment of complicity and sharing.",
      "bubble-points-quoted-3-addon": "It's Stowy time!",
      "how-why-1": "How does it work?",
      "how-why-2": "I download the Stowy app on my tablet or phone.",
      "how-why-3": "I create an avatar with my child in their image.",
      "how-why-4": "I access dozens of original and illustrated stories, which are read like a book.",
      "how-why-5": "I subscribe to access all the stories unlimitedly.",
      "why-trust": "Why trust us?",
      "why-pedagogic-1": "Stowy is educational",
      "why-pedagogic-2": "All our stories are written in close collaboration with early childhood professionals.",
      "why-pedagogic-3": "The educational objectives of each story are explained at the beginning.",
      "why-family-1": "Designed for families",
      "why-family-2": "Stowy is an experience designed to be shared with the family. No 'tablet babysitter', and controlled and qualitative screen time for children...",
      "why-family-3": "A gentle and intelligent introduction to digital content.",
      "why-mif-1": "100% Made in France",
      "why-mif-2": "Our stories are born in a small village in Beaujolais.",
      "why-mif-3": "Nowadays, it's rare enough to mention :)",
      "who-1": "Who are we?",
      "who-2": "Stowy was born in the mind of a young dad who often feels like he spends more time choosing the bedtime story than reading it! Our mission: to make you spend unforgettable moments with your family. Here is the team of experts we have assembled to achieve this.",
      "who-kevin-name": "Kevin",
      "who-kevin-role": "CEO",
      "who-kevin-1": "After many experiences in digital, he returns here to his first loves: technical innovation at the service of art.",
      "who-kevin-2": "He cooks amazing banana tartlets. Ask him for the recipe, it's worth the detour.",
      "who-seb-name": "Sebastian",
      "who-seb-role": "CTO",
      "who-seb-1": "Our samurai of technology has as many screens in front of his eyes as ideas per second, and it's thanks to him that all this is possible. He transforms our ideas into an application that hardly ever bugs.",
      "who-seb-2": "He only distills this ancient art to the rare students who prove themselves worthy.",
      "who-rachel-name": "Rachel",
      "who-rachel-role": "Early Childhood Manager",
      "who-rachel-1": "She checks the educational objectives, tames complicated words, vetoes when it's too scary: this early childhood professional is our great guardian, thanks to whom your little ones enjoy exciting stories in complete tranquility.",
      "who-rachel-2": "It seems she never does Dodo (the bird).",
      "who-lanternes-name": "The lantern creatures",
      "who-lanternes-role": "Little artistic monsters",
      "who-lanternes-1": "Creatures because they create, lanterns because they light the way, our little monsters elevate the wildest ideas into unforgettable stories. Their brushstroke is recognized all the way to the great plains of the West.",
      "who-lanternes-2": "Fun fact: at the full moon, they revert to human appearance.",
      "roadmap-1": "The Stowy application is not quite finished yet.",
      "roadmap-2": "Help us build it as",
      "roadmap-3": "YOU",
      "roadmap-4": " would love!",
      "plan-free-1": "I want to follow the progress closely",
      "plan-free-2": "I am informed by email of new features and advancements",
      "plan-free-3": "As a gift, right now: a story offered in PDF format!",
      "plan-free-4": "Free",
      "plan-solo-1": "I wish to pre-register for 1 child",
      "plan-solo-2": "3 months of “Child Solo” subscription offered at the launch of the application.",
      "plan-solo-3": "3 stories in PDF format, sent by email right now.",
      "plan-solo-4": "Offer limited to the first 500 registrants.",
      "plan-solo-5": "$5.99",
      "plan-solo-6": "I save $11.98 and help a young company in development!",
      "plan-family-1": "I wish to pre-register for several children",
      "plan-family-2": "3 months of “Multi-Child” subscription offered at the launch of the application.",
      "plan-family-3": "5 stories in PDF format, sent by email right now.",
      "plan-family-4": "Offer limited to the first 500 registrants.",
      "plan-family-5": "$8.99",
      "plan-family-6": "I save $17.98 and help a young company in development!",
      "placeholder-firstname": "My first name",
      "placeholder-lastname": "My last name",
      "placeholder-email": "My email address",
      "registering-ongoing": "Registration in progress",
      "registering-done": "Thank you for your support!",
      "checkout-ongoing": "Communicating with our secure payment provider...",
      "checkout-pending": "Secure payment link created",
      "cookie-usage-1": "We use cookies to guarantee you the best possible experience.",
      "cgv-checkbox-1": "I accept",
      "cgv-checkbox-2": "the terms and conditions of sale.",
      "contact-address": "Contact: kevin[at]stowy[.]fr",
      "copyright-title": "© 2021 - Stowy",
      "copyright-1": "Stowy SAS with a capital of €10,000",
      "copyright-2": "878 788 419 R.C.S.",
      "cookies-title": "Cookies",
      "cookies-intro": "We use cookies to analyze your browsing habits, offer you content and services tailored to your interests, and to compile usage statistics of our website, in other words: to improve our site and your user experience.",
      "cookies-option-1": "Essential",
      "cookies-option-2": "Functional",
      "cookies-option-3": "Advertising",
      "cookies-button-configure": "Configure",
      "cookies-button-accept": "Accept",
      "cookies-essentials-title": "Essentials",
      "cookies-essentials-desc": "They are strictly necessary to navigate the web, enabling basic functions such as navigating from one section to another. They are in charge of technical aspects such as identifying profiles or sessions and managing tool preferences in the use, interaction, and location of access to content. The mandatory cookies used for this site are as follows:",
      "cookies-essentials-stowy-desc-1": "Stowy",
      "cookies-essentials-stowy-desc-2": "We use cookies to analyze your browsing habits, offer you content and services tailored to your interests, and to compile usage statistics of our website, in other words: to improve our site and your user experience.",
      "cookies-essentials-stowy-desc-3": "The following tags are necessary for the proper functioning of our website.",
      "cookies-essentials-stowy-desc-4": "Purpose:",
      "cookies-essentials-stowy-desc-5": "The collected data are used to identify the user, to connect, to pass and view orders.",
      "cookies-essentials-stowy-desc-6": "Technologies used:",
      "cookies-essentials-stowy-desc-7": "Cookies",
      "cookies-essentials-stowy-desc-8": "Local storage",
      "cookies-essentials-stowy-desc-9": "Collected data",
      "cookies-essentials-stowy-desc-10": "Order information: name, first name, email, and delivery address.",
      "cookies-essentials-stowy-desc-11": "User ID to keep the session connected.",
      "cookies-essentials-gtm-desc-1": "Google Tag Manager",
      "cookies-essentials-gtm-desc-2": "This is a tag management system for managing JavaScript and HTML tags used for tracking and analytics on your website.",
      "cookies-essentials-gtm-desc-3": "The following tags are necessary for the proper functioning of our website.",
      "cookies-essentials-gtm-desc-4": "Purpose:",
      "cookies-essentials-gtm-desc-5": "Website tag management",
      "cookies-essentials-gtm-desc-6": "Technologies used:",
      "cookies-essentials-gtm-desc-7": "Cookies",
      "cookies-essentials-gtm-desc-8": "Collected data",
      "cookies-essentials-gtm-desc-9": "No data is collected.",
      "cookies-functionals-title": "Functional",
      "cookies-functionals-ga-desc-1": "Google Analytics",
      "cookies-functionals-ga-desc-2": "These tags allow us to analyze the use of our website in order to measure and improve its performance.",
      "cookies-functionals-ga-desc-3": "The following tags are necessary for the proper functioning of our website.",
      "cookies-functionals-ga-desc-4": "Purpose:",
      "cookies-functionals-ga-desc-5": "Analysis and optimization",
      "cookies-functionals-ga-desc-6": "Technologies used:",
      "cookies-functionals-ga-desc-7": "Cookies",
      "cookies-functionals-ga-desc-8": "Collected data:",
      "cookies-functionals-ga-desc-9": "Referrer URL, sub-pages visited, frequency, and duration of sub-page visits.",
      "cookies": {
        "essential": {
          "description": "Essential cookies are necessary for the proper functioning of the application, such as user session management and consent management. They cannot be disabled.",
          "list": {
            "session": {
              "name": "Session Cookie",
              "description": "Keeps the user's session active.",
              "data": "Session ID, authentication status",
              "duration": "Duration: Session"
            },
            "consent": {
              "name": "Consent Cookie",
              "description": "Stores the user's consent preferences.",
              "data": "Consent preferences",
              "duration": "Duration: 12 months"
            },
            "app": {
              "name": "Application Cookies",
              "description": "Used to save your character's configuration, the appearance of the tutorial, and story data.",
              "data": "Application data",
              "duration": "Variable"
            }
          }
        },
        "analytics": {
          "description": "Analytics cookies are used to analyze traffic and improve the application. They are enabled only after consent.",
          "list": {
            "_ga": {
              "name": "Google Analytics (_ga)",
              "description": "Used to distinguish users and track site traffic.",
              "data": "IP address, pages visited, session duration, clicks",
              "duration": "Duration: 2 years"
            },
            "_gid": {
              "name": "Google Analytics (_gid)",
              "description": "Used to distinguish users for statistical purposes.",
              "data": "IP address, pages visited, session duration",
              "duration": "Duration: 24 hours"
            },
            "_gat": {
              "name": "Google Analytics (_gat)",
              "description": "Used to limit the number of requests sent.",
              "data": "No data collected",
              "duration": "Duration: 1 minute"
            }
          }
        },
        "advertising": {
          "description": "Advertising cookies are used to personalize ads and measure their effectiveness. They are enabled only after consent.",
          "list": {
            "_gcl_au": {
              "name": "Google Ads (_gcl_au)",
              "description": "Used to track ad conversions.",
              "data": "Unique identifiers, ad interactions, IP address",
              "duration": "Duration: 90 days"
            }
          }
        }
      },
      "cookies-button-accept-all": "Accept all",
      "cookies-button-accept-selection": "Accept selection"
    },
  
    "cgu": {
    "title": "Terms of Use",
    "p1": "The use of the \"STOWY\" activity implies acceptance without reservation of these Terms of Use (hereinafter referred to as the \"Terms\").",
    "p2": "This agreement (hereinafter referred to as the \"Agreement\") constitutes the terms of use between you, as an individual or single legal entity (hereinafter referred to as the \"User\"), and the company STOWY (hereinafter referred to as the \"Publisher\").",
    "a1-title": "ARTICLE 1 - DEFINITIONS",
    "a1-p1": "The terms defined below will have the following meaning:",
    "a1-p2": "\"Terms\": refers to the Terms of Use governing the use of the STOWY mobile application and the website www.stowy.fr, accessible at the following address: www.stowy.fr/cgu-cgv ;",
    "a1-p3": "\"Application\": refers to the mobile application designed, developed, and operated by STOWY, which is downloadable from mobile and tablet app download platforms ;",
    "a1-p4": "\"Website\": refers to the website designed, developed, and operated by STOWY, whose URL is as follows: www.stowy.fr ;",
    "a1-p5": "\"User\": refers to any person using STOWY via the mobile application ;",
    "a1-p6": "\"Publisher\": refers to the company STOWY SAS, located at 50 Chemin des Caillots, 71670 Saint Firmin, registered under SIREN number 898788419 in the Trade and Companies Register (RCS) of Chalon-sur-Saône ;",
    "a1-p7": "\"Content\": refers to the data provided by STOWY through the application.",
    "a2-title": "ARTICLE 2 - PURPOSE",
    "a2-p1": "These Terms constitute, as of 01/10/2024, the Terms of Use of the STOWY Application.",
    "a2-p2": "They apply to the exclusion of all other conditions.",
    "a2-p3": "The Publisher reserves the right to modify the Terms at any time. In the event of modification, the User will be informed by email, sent to the address provided to the Publisher upon registration, at least one (1) month before the new Terms come into effect. If the User has not created an account or can no longer access the email address provided, it is their responsibility to regularly review the latest version of the Terms on the Website.",
    "a2-p4": "The applicable Terms are those in effect on the date of the User's registration and during each use, subject to modifications.",
    "a2-p5": "As a result, the User is invited to regularly check the Application or their emails to stay informed of changes to the Terms.",
    "a3-title": "ARTICLE 3 - ACCESS TO STOWY",
    "a3-p1": "Access to the STOWY application is free and limited under a free offer called \"Free Kid\". The application provides limited access to a certain number of stories for one child, without financial compensation.",
    "a3-p2": "To access the full range of stories and features, a paid subscription is required. STOWY reserves the right to modify or restrict access to the \"Free Kid\" offer without notice.",
    "a4-title": "ARTICLE 4 - APPLICATION MODIFICATIONS",
    "a4-p1": "The Publisher reserves the right, at any time, to modify or temporarily or permanently interrupt the Application without notice and without incurring any liability. These modifications may include technical updates, feature improvements, or legal requirements. The User acknowledges that no compensation may be claimed in this regard.",
    "a4-p2": "Under the free offer (“Free Kid”), the available stories and their number may change regularly and randomly, without necessarily informing the User in advance.",
    "a5-title": "ARTICLE 5 - USER OBLIGATIONS",
    "a5-p1": "The User agrees to comply with these Terms and all applicable laws and regulations in France or in their country of residence.",
    "a5-p2": "- Respect the intellectual property rights related to the content provided by the Website and the Application ;",
    "a5-p3": "- Not use the Application for illegal purposes, such as solicitation or prostitution ;",
    "a5-p4": "- Not make or disseminate statements or content that violate the rights of others, are defamatory, offensive, obscene, violent, incite hatred, or are contrary to laws, regulations, or moral standards ;",
    "a5-p5": "- Not post or disseminate information or content likely to disrupt or prevent the normal use of the Application, such as malware, viruses, logic bombs, or mass message sendings ;",
    "a5-p6": "- Not interrupt, suspend, slow down, or prevent the proper functioning of the Website or Application ;",
    "a5-p7": "- Not attempt to intrude into the systems of the Company or engage in any hacking activity, including disassembling, analyzing, or reproducing the Application's source code ;",
    "a5-p8": "- Not divert the system resources of the Website or Application for purposes other than those for which they were designed ;",
    "a5-p9": "- Not impose a disproportionate load on the Website or Application infrastructures ;",
    "a5-p10": "- Not breach the security and authentication measures set up by the Company ;",
    "a5-p11": "- Not access or attempt to access technical resources or content that is not explicitly included in their subscription, by any means whatsoever ;",
    "a5-p12": "- Not harm the financial, commercial, moral rights, or reputation of the Company or its users ;",
    "a5-p13": "- Not monetize, sell, or grant any access to the Website, Application, or the information they contain.",
    "a6-title": "ARTICLE 6 - AVAILABILITY OF THE SERVICE",
    "a6-p1": "The Application is accessible to the User 24 hours a day, 7 days a week, all year long. However, the Publisher reserves the right, without notice or compensation, to temporarily or permanently close access, and cannot be held responsible for any damage of any kind that may result.",
    "a7-title": "ARTICLE 7 - ADVERTISING",
    "a7-p1": "The application operates without advertisements. However, the Company reserves the right to insert advertising or promotional messages on any page of the Website in a form and under conditions that the Company will judge at its sole discretion.",
    "a8-title": "ARTICLE 8 - UNSUBSCRIPTION",
    "a8-p1": "The User may at any time choose to unsubscribe from the STOWY service. The unsubscription procedure depends on whether the User has an account or not.",
    "a8-p2": "If the User does not have an account: They must go to the STOWY application, click on the settings button located at the bottom right of the screen, represented by an icon of an adult with a child, then select the option \"Delete my information\". This action will delete personal information related to the use of the application.",
    "a8-p3": "If the User has an account: They must log into their personal account via the Website at http://www.stowy.fr/account, sign in with their credentials, then go to the \"Subscription\" tab and click on \"Unsubscribe\". This action will deactivate their account and delete their access to the application's services.",
    "a8-p4": "Data retention: In accordance with applicable law, only strictly necessary commercial data (such as the purchaser's identity, payment details, etc.) will be retained by the Publisher for ten (10) years from the date of unsubscription, as part of its legal and accounting obligations.",
    "a9-title": "ARTICLE 9 - APPLICABLE LAW AND JURISDICTION",
    "a9-p1": "These Terms of Use are governed, interpreted, and applied in accordance with French law, with the French language being the language of interpretation in case of dispute over the meaning of a term or provision of the Terms of Use.",
    "a10-title": "ARTICLE 10 - USE OF THE APPLICATION BY CHILDREN AND RESPONSIBILITY OF LEGAL REPRESENTATIVES",
    "a10-p1": "The STOWY Application is designed to be used by children under the exclusive supervision and responsibility of parents or legal guardians. The Publisher reminds that screen use by children must comply with official recommendations, such as those established by health authorities, the State, or healthcare professionals, including the \"3-6-9-12\" principles.",
    "a10-p2": "The Publisher disclaims any responsibility for any damage, developmental delays, behavioral disorders, or any other direct or indirect harm that may be caused to a child in connection with the use of the Application. It is the responsibility of parents or legal guardians to monitor and regulate the use of the Application by the child, ensuring that medical recommendations and best practices regarding screen exposure are followed.",
    "a10-p3": "- Limit children's screen time in accordance with the recommendations of doctors and competent authorities ;",
    "a10-p4": "- Monitor any abnormal use or suspicious behavior related to the use of the Application, and, in case of doubt, immediately stop access to the Application ;",
    "a10-p5": "- Do not allow the use of the Application late at night, during the night, or at times when it could disrupt the child's sleep patterns ;",
    "a10-p6": "- Accompany the use of the Application to foster a supervised, enriching experience that is appropriate for the child's age ;",
    "a10-p7": "- Ensure that the content of the Application is viewed in a healthy environment and under conditions favorable to the child's well-being.",
    "a10-p8": "The Publisher shall not be held responsible in any way for the effects of excessive or inappropriate use of the Application, nor for the consequences on the child's physical or mental well-being. The responsibility for monitoring and controlling the use of the Application by children rests exclusively with the parents or legal guardians.",
    "a11-title": "ARTICLE 11 - EFFECTIVE DATE",
    "a11-p1": "These Terms of Use took effect on 10-06-2024 (MM-DD-YYYY)."
  },
  "cgv": {
    "title": "Terms and Conditions of Sale",
    "p1": "These terms and conditions of sale (CGV) govern the use of the STOWY mobile application and the website http://www.stowy.fr, and are accessible at the following address: http://www.stowy.fr/cgu-cgv.",
    "a1-title": "ARTICLE 1 - DEFINITIONS",
    "a1-p1": "The terms defined below will have the following meaning:",
    "a1-p2": "\"CGV\": refers to the Terms and Conditions of Sale governing the use of the STOWY mobile application and the website http://www.stowy.fr, accessible at the following address: http://www.stowy.fr/cgu-cgv.",
    "a1-p3": "\"Application\": refers to the mobile application designed, developed, and operated by STOWY, which is downloadable from mobile and tablet app platforms.",
    "a1-p4": "\"Website\": refers to the website designed, developed, and operated by STOWY, whose URL is as follows: http://www.stowy.fr.",
    "a1-p5": "\"Buyer\" : refers to any individual or legal entity, acting either as a consumer or a professional, and purchasing Products offered by the Company on the Website.",
    "a1-p6": "\"The Company\": refers to the company STOWY, whose detailed information is specified in Article 3 of these Terms and Conditions.",
    "a1-p7": "The \"Content\": refers to the data provided by STOWY via the application.",
    "a1-p8": "\"The Products\": refers to subscriptions, books, activity books, derivative products, or any other product or service, tangible or intangible, sold online by the Company on the Website.",
    "a2-title": "ARTICLE 2 - PURPOSE",
    "a2-p1": "These terms and conditions of sale apply without restriction or reservation to all online sales offered by the company STOWY on the Website.",
    "a2-p2": "The purpose of these terms is to define the conditions of sale and delivery of the Products, as well as the rights and obligations of the parties.",
    "a2-p3": "They are accessible and printable at any time via a direct and permanent link on the Website.",
    "a2-p4": "These terms and conditions prevail over all other general or specific conditions, including those of the buyer, unless expressly accepted in writing by STOWY.",
    "a2-p5": "The Publisher reserves the right to modify the CGV at any time. In case of modification, the User will be informed by email, sent to the address provided upon registration, at least one (1) month before the new CGV come into effect.",
    "a3-title": "ARTICLE 3 - SELLER'S IDENTITY AND CONTACT DETAILS",
    "a3-p1": "The Website and the Application are edited and operated by the company STOWY, a simplified joint-stock company (Société par Actions Simplifiée) with a capital of 10,000 euros, registered with the Trade and Companies Register of CHALON SUR SAONE under number 898 788 419.",
    "a3-p2": "The Company's purpose, both in France and abroad, includes software publishing, web or mobile applications, as well as designing, developing, adapting, testing, supporting, and maintaining software and web or mobile applications.",
    "a3-p3": "The Company also engages in the commercialization, sale, rental, and provision of software and applications, for a fee or otherwise.",
    "a3-p4": "(hereinafter referred to as the \"Company\").",
    "a3-p5": "The Company can be contacted at the following email address: kevin@stowy.fr.",
    "a4-title": "ARTICLE 4 - ACCEPTANCE OF THE GENERAL TERMS OF SALE",
    "a4-p1": "The Buyer declares, prior to placing an order, that they have full legal capacity, enabling them to commit to these general terms of sale.",
    "a4-p2": "Their acceptance of these terms is materialized by checking a box in the order form. This acceptance can only be full and unconditional.",
    "a4-p3": "Any acceptance with reservations is considered null and void.",
    "a4-p4": "The Buyer who does not accept these general terms of sale should not place an order on the Site.",
    "a5-title": "ARTICLE 5 - PRODUCT CHARACTERISTICS",
    "a5-p1": "Before placing any order, the Buyer agrees to review all information related to the Products offered on the Site.",
    "a5-p2": "The Buyer is invited to consult the detailed descriptions of the Products available on the Site to ensure that they meet their specific needs and requirements.",
    "a5-p3": "The Company cannot be held responsible, and no refund will be issued, in the event of improper use of the Products or technical incompatibility.",
    "a5-p4": "The Products offered, whether material or immaterial, comply with the standards in force on French territory and with European legislation.",
    "a5-p5": "Product availability is indicated within the limits of available stocks or technical capacities.",
    "a5-p6": "Photographs, representations, and descriptions of the Products on the Site are as accurate as possible, but only bind the Company for what is explicitly stated.",
    "a6-title": "ARTICLE 6 - ORDER",
    "a6-1-title": "6.1 Placing the Order",
    "a6-1-p1": "To place an order, the Buyer must select the Products of their choice and pay by credit card via our payment partner Stripe.",
    "a6-1-p2": "As part of their order, the Buyer is required to provide their billing information.",
    "a6-1-p3": "They must complete all fields marked as mandatory in the form provided for this purpose.",
    "a6-1-p4": "Orders that do not include all required information cannot be validated.",
    "a6-1-p5": "The Buyer guarantees that all the information they provide in the order form is accurate, up-to-date, and sincere, and is not misleading in any way.",
    "a6-1-p6": "They are informed and agree that this information serves as proof of their identity and binds them upon validation.",
    "a6-2-title": "6.2 Order Confirmation",
    "a6-2-p1": "At the end of their order, the Buyer receives an email confirmation of their order.",
    "a6-2-p2": "The Buyer must ensure that the contact details they provided when placing their order or updating their Account are correct and allow them to receive the order confirmation email.",
    "a6-2-p3": "In the absence of receipt of this email, the Buyer must contact the Company using the contact details provided in Article 2.",
    "a6-2-p4": "The Company recommends that the Buyer keeps the information contained in the order confirmation.",
    "a6-2-p5": "The order and its confirmation are considered received when the parties to whom they are addressed can access them.",
    "a6-3-title": "6.3 Subscription Renewal Terms",
    "a6-3-p1": "The User may at any time notify the Publisher of their wish to terminate their Subscription at no cost other than those potentially associated with the transmission of their request.",
    "a6-3-p2": "Termination will take effect on the expiration date of the current Subscription, provided that the notification is made by the User at least 48 hours before the expiration date of the current Subscription, in accordance with the provisions of ARTICLE 16 below 'Termination'.",
    "a6-3-p3": "At the expiration of a Subscription, it will be automatically renewed for periods equivalent to the one initially chosen by the User, unless the User notifies the Publisher of their wish to terminate before the expiration date of the current Subscription.",
    "a6-3-p4": "The renewed Subscription will be billed to the User based on the initial subscription's pricing and periodicity.",
    "a6-3-p5": "The User may cancel the renewed subscription at any time for an indefinite period, which will then end at the conclusion of the current subscription period, provided that the User notifies the Publisher at least 48 hours before the expiration date of the current Subscription.",
    "a6-3-p6": "In accordance with article L 136-1 of the French Consumer Code, the publisher will inform the concerned user by sending an email at least thirty (30) days before the expiration date of the initial Subscription, stating that they can terminate their Subscription free of charge before the expiration date. If not, the Subscription will be renewed for periods equivalent to the one initially chosen by the User until the User notifies the Publisher of their wish to terminate their Subscription, as described in Article 7 'Termination' of these GTC.",
    "a6-4-title": "6.4 Account Usage",
    "a6-4-p1": "The User acknowledges that the subscriptions they purchase are strictly personal and may not be shared with third parties, whether free or for a fee.",
    "a6-4-p2": "Using an account by multiple people or sharing access credentials with third parties is strictly prohibited.",
    "a6-4-p3": "If account sharing is detected, the Company reserves the right to suspend or terminate the User's subscription without notice, in accordance with the provisions of Article 7 - Termination.",
    "a6-4-p4": "This measure may be taken without the User being entitled to a refund for the remaining subscription period.",
    "a7-title": "ARTICLE 7 - TERMINATION",
    "a7-p1": "To terminate their Subscription, the User must go to http://www.stowy.fr/account, log in to their account, and then click on the 'Subscription' tab.",
    "a7-p2": "The User can then either choose the 'Change offer' option to switch to the free 'Free Kid' offer, or decide to completely unsubscribe by selecting 'Unsubscribe'.",
    "a7-p3": "This unsubscription will result in the deactivation of the account and the removal of access to the Application's services.",
    "a7-p4": "The termination of a Subscription will take effect on the expiration date of the current Subscription, provided that the notification is made at least 48 hours before the end of the billing period.",
    "a7-p5": "In case of termination by the User, no refunds will be made for unused periods.",
    "a7-p6": "In the event of a serious breach by the User of these GTC or the TOU, the Publisher reserves the right to terminate the User's account without notice.",
    "a7-p7": "This termination will have the same effects as a termination initiated by the User, except that the service interruption may be immediate.",
    "a7-p8": "The User will be informed by email of the termination of their account.",
    "a7-p9": "The data relating to the User will be deleted or retained in accordance with legal timeframes after the account termination.",
    "a8-title": "ARTICLE 8 - PRICE AND PAYMENT TERMS",
    "a8-p1-title": "8.1 Price",
    "a8-p1-1": "For online orders, the sale prices of the Products are indicated on the Site. They are shown in euros, including all French taxes (French VAT and other applicable taxes). They include delivery costs for certain subscription-related products.",
    "a8-p1-2": "In the case of a delivery service integration, the applicable fees will be indicated before the Buyer confirms the order. The applicable price is the one displayed on the Site at the time the Buyer's order is recorded.",
    "a8-p1-3": "The Application is free to download with limited access to stories and features. To enjoy unlimited access to all stories, the User must subscribe if they wish to keep access to all stories. The monthly subscription cost will be €3.99 per month for one child (Solo Kid Plan) or €5.99 for an unlimited number of children (Multi Kids Plan). The price may vary during promotional periods.",
    "a8-p1-4": "The Publisher reserves the right to change subscription prices at any time. In the event of a price change, subscribed Users will be informed by email at least one (1) month before the new prices take effect. The User will then have the option to cancel their subscription if they do not wish to accept the new rates. In the absence of cancellation, the new prices will be automatically applied from the next billing cycle.",
    "a8-p2-title": "8.2 Payment Terms",
    "a8-p2-1": "The total price of the Products and applicable delivery costs are due at the time of order. Payment can be made online by credit card or by any other method that may be offered on the Site at the time of order. The Buyer guarantees to the Company that they have the necessary authorizations to use the chosen payment method. The Company reserves the right to suspend or cancel any order and/or delivery in the event of non-payment of any amount due by the Buyer, in the event of a payment incident, or in the case of fraud or attempted fraud.",
    "a8-p3-title": "8.3 Ownership",
    "a8-p3-1": "The Company retains full ownership of the physical Products sold until full payment of the price is received.",
    "a8-p3-2": "For intangible Products, such as the source code of the Application and its Content accessible via subscription, STOWY remains the exclusive owner of all Content. The Buyer only has a personal, non-exclusive, and non-transferable right of use within the framework of their subscription, without the possibility of reproduction, modification, or commercial exploitation.",
    "a8-p4-title": "8.4 Subscription Management and Failed Payments",
    "a8-p4-1": "Subscriptions taken out by the User are managed by Stripe, our payment partner. In the event of a payment failure, several automatic debit attempts will be made. If, after several attempts, the payment still fails, the User's subscription will be suspended, and their access to the Application's premium features will be blocked.",
    "a8-p4-2": "In the event of a payment failure, the User will receive email notifications reminding them to update their payment information. The User has a period of time before their account is suspended to rectify the situation. In the absence of rectification, access to the Application will be blocked, although the User can still access their account via http://www.stowy.fr/account to reactivate their subscription.",
    "a8-p4-3": "Once payment has been rectified, access to premium features will be automatically restored without requiring reconnection. If the User wishes to unsubscribe, they can follow the steps described in Article 7 - Termination. Once unsubscribed, the User will retain access to their subscription until the end of the current period, with no further billing.",
    "a9-title": "ARTICLE 9 - RIGHT OF WITHDRAWAL",
    "a9-p1": "For the purchase of physical products, the Buyer has a period of seven clear days from the date of receipt of the ordered Products to withdraw without having to justify any reasons or pay any penalties, except for return costs. The Buyer must contact the Company by registered mail at 50 Chemin des Caillots, 71670 SAINT FIRMIN, to inform them of their intention to exercise their right of withdrawal. The letter must include: the order number, the Buyer's name, the order date, and the reason for withdrawal. The Buyer will then return the Products to the Company by any means. The Products must be returned to the Company in their original unopened packaging, with all their accessories, without any signs of use. They must be accompanied by a copy of the corresponding purchase invoice. The Buyer will be reimbursed as soon as possible, and at the latest within 30 (thirty) days following the date of receipt of the returned Products, for the total amount paid for the order, minus return costs and any missing products. No order cancellation can take place outside the exercise of the right of withdrawal under the conditions described above.",
    "a9-p2": "For the purchase of intangible Products (such as subscriptions or digital content), the Buyer expressly waives any right of withdrawal as soon as they access or begin to use the product immediately, in accordance with Article L221-28 of the French Consumer Code. By accepting these GTC, the Buyer acknowledges that their right of withdrawal cannot be exercised once access to the digital content has been initiated or consumed.",
    "a10-title": "ARTICLE 10 - LEGAL GUARANTEES",
    "a10-p1": "The Buyer benefits from the legal guarantees of conformity as well as the guarantees against hidden defects of the item sold, in accordance with Articles 1641 and following of the French Civil Code and Articles L217-4 and following of the French Consumer Code. If the Buyer finds that the delivered Product has a defect, non-conformity, or is damaged, they must inform the Company at the contact details provided in Article 2 of these terms, specifying the nature of the defect, non-conformity, or damage, within a reasonable time following the discovery of the problem.",
    "a10-p2": "The Company will arrange the return process in collaboration with the carrier of its choice and will inform the Buyer of the return procedures by any useful means.",
    "a10-p3": "The Company will cover the return costs of defective or non-compliant Products. The Products must be returned to the Company in their original packaging, along with all their accessories and a copy of the corresponding purchase invoice. Returns that do not comply with the conditions described above cannot be accepted.",
    "a10-p4": "Upon receipt and verification of the returned Products, the Company will offer the Buyer either the repair or replacement of the Product, to the extent possible. If repair or replacement of the Product is impossible, the Company will refund the Buyer the full amount paid for the Product, as well as the corresponding shipping costs. The refund will be made as soon as possible and no later than fifteen (15) days following the confirmation of the impossibility to repair or replace the Product.",
    "a11-title": "ARTICLE 11 - LINKS AND THIRD-PARTY SITES",
    "a11-p1": "The Company cannot be held liable for the technical availability of websites or mobile applications operated by third parties (including its potential partners) to which the Buyer may access through the Site. The Company disclaims any liability for the content, advertising, products and/or services available on such third-party websites or mobile applications, which are governed by their own terms of use.",
    "a11-p2": "The Company is also not responsible for transactions conducted between the Buyer and any advertiser, professional, or merchant (including its partners) to whom the Buyer was directed via the Site. Consequently, the Company shall not be involved in any disputes that may arise, particularly regarding the delivery of products or services, guarantees, representations, or any other obligations to which these third parties may be subject.",
    "a11-p3": "Moreover, the Company cannot be held liable for statements or communications made by third parties, such as business partners or external promoters, regarding the Company's Products or services, when such statements do not reflect the reality of the offers made available by the Company. The Buyer is invited to refer solely to the information available on the Site or directly communicated by the Company to know the characteristics and terms of the Products or services offered.",
    "a12-title": "ARTICLE 12 - APPLICABLE LAW AND JURISDICTION",
    "a12-p1": "These general conditions are governed by French law. In case of any dispute regarding the validity, interpretation, and/or execution of these general conditions, the parties agree that the courts of Paris shall have exclusive jurisdiction, unless otherwise provided by mandatory legal procedures.",
    "a13-title": "ARTICLE 13 - EFFECTIVE DATE",
    "a13-p1": "These General Terms and Conditions of Sale took effect on 10-06-2024 (MM-DD-YYYY).",
  },
  "legals": {
    "title": "Legal Notice",
    "a1-title": "1. Website and App Publisher",
    "a1-p1": "The website www.stowy.fr and the STOWY mobile app are published by:",
    "a1-p2": "STOWY SAS",
    "a1-p3": "A Simplified Joint Stock Company with a capital of 10,000 euros",
    "a1-p4": "Registered with the Chalon-sur-Saône Trade and Companies Register under number 898 788 419",
    "a1-p5": "Headquarters: 50 Chemin des Caillots, 71670 Saint Firmin",
    "a1-p6": "Email address: kevin [at] stowy [dot] fr",
    "a1-p7": "Publication Director: Kévin Pomorski",

    "a2-title": "2. Hosting",
    "a2-p1": "The website www.stowy.fr is hosted by:",
    "a2-p2": "Google Cloud Platform (Europe Region – Ireland)",
    "a2-p3": "Address: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland",
    "a2-p4": "Website: https://cloud.google.com",
    "a2-p5": "Phone: +353 1 543 1000",

    "a3-title": "3. Intellectual Property",
    "a3-p1": "All elements present on the website www.stowy.fr and the STOWY app, including text, images, photographs, videos, logos, graphics, sounds, databases, software, and other content, are protected by intellectual property laws and are the exclusive property of STOWY SAS or its partners.",
    "a3-p2": "Any reproduction, representation, modification, publication, transmission, distortion, or total or partial exploitation of these elements, by any means, without prior written authorization from STOWY SAS, is strictly prohibited and constitutes an infringement punishable by Articles L.335-2 and following of the Intellectual Property Code.",

    "a4-title": "4. Modifications",
    "a4-p1": "STOWY SAS reserves the right to modify and update, without notice, this legal notice, as well as any content or elements of the website or app. Users are invited to consult this page regularly to stay informed of any changes.",

    "a5-title": "5. Contact",
    "a5-p1": "For any questions regarding this legal notice, you can contact us at the following address: kevin [at] stowy [dot] fr"
},


  "confidentiality": {
    "title": "Privacy Policy",
    "a1-title": "1. Introduction",
    "a1-p1": "This privacy policy aims to inform users of the STOWY application and the website www.stowy.fr about the methods of collecting, processing, and protecting their personal data, in accordance with the General Data Protection Regulation (GDPR) and other applicable data protection laws.",

    "a2-title": "2. Identity and contact details of the data controller",
    "a2-p1": "The data controller for the personal data collected is:",
    "a2-p2": "STOWY SAS",
    "a2-p3": "Simplified Joint Stock Company registered with the Chalon-sur-Saône Trade and Companies Register under number 898 788 419",
    "a2-p4": "Head office: 50 Chemin des Caillots, 71670 Saint Firmin",
    "a2-p5": "Email address: kevin [at] stowy [point] fr",

    "a3-title": "3. Management of personal data",
    "a3-sub1-title": "3.1 Personal data collected",
    "a3-sub1-p1": "- Name, First name, Email address, Postal address, Phone number: User account management, commercial information, billing, compliance with regulatory obligations. Retention period: 5 years. Third parties involved: HubSpot, Stripe.",
    "a3-sub1-p2": "- Payment data (Credit card, Transaction details): Purchases, commercial follow-up, billing. Retention period: 5 years. Third parties involved: HubSpot, Stripe.",
    "a3-sub1-p3": "- IP address: Service provision, user identification. Retention period: 1 year. Third parties involved: Google, Stripe, jQuery.",
    "a3-sub1-p4": "- Usage data (History of actions performed on the site, history of actions performed on the application, history of stories read, interactions with application content): Statistics, system improvement, customer support. Retention period: 1 year. Third parties involved: Google.",

    "a3-sub2-title": "3.2 Sharing data with third parties as part of providing the Service",
    "a3-sub2-p1": "To ensure the proper functioning of our service, guarantee its continuity, and improve the quality of our platform, your personal data may be shared with certain third parties. These partners act as essential service providers, particularly for payment management, performance analysis, and improving the user experience. Here is the list of companies with which your data may be shared:",
    "a3-sub2-p2": "- HubSpot Ireland Limited: 2nd Floor, One Dockland Central, Guild Street, Dublin 1, Ireland.",
    "a3-sub2-p3": "- Google Ireland Limited: Gordon House, Barrow Street, Dublin 4, Ireland.",
    "a3-sub2-p4": "- Stripe Payments Europe, Limited: The One Building, 1 Grand Canal Street Lower, Dublin 2, Ireland.",
    "a3-sub2-p5": "- jQuery Foundation (JS Foundation): 1 Letterman Drive, Building D, Suite D4700, San Francisco, CA 94129, United States.",

    "a3-sub3-title": "3.3 Characters created in the application",
    "a3-sub3-p1": "The characters created in the application, including their name and physical appearance, are considered fictitious and cannot in any case be treated as personal data under the GDPR.",

    "a4-title": "4. Cookies and similar technologies",
    "a4-p1": "When browsing the Site or the Application, cookies may be used to collect non-personal information to improve the user experience and analyze the audience.",
    "a4-p2": "The types of cookies used include:",
    "a4-p3": "- **Essential cookies**: Necessary for the proper functioning of the application (user session, consent management). Due to their imperative nature, these cookies cannot be disabled.",
    "a4-p4": "- **Analytical cookies**: Used to analyze traffic and improve the application, activated only after consent.",
    "a4-p5": "- **Advertising cookies**: Used to personalize ads and measure their effectiveness, activated only after consent.",

    "a5-title": "5. Detailed list of cookies used",
    "a5-p1": "- **Session Cookie** (Essential): Keeps the user's session active. Data collected: Session ID, authentication state. Duration: Session.",
    "a5-p2": "- **Consent Cookie** (Essential): Records the user's consent preferences. Data collected: Consent preferences. Duration: 12 months. No sharing with third parties.",
    "a5-p3": "- **Application cookies** (Essential): Used by the application to record your character configuration, tutorial appearance, story data.",
    "a5-p4": "- **Google Analytics (_ga)** (Analytical): Used by Google Analytics to distinguish users and track site traffic. Data collected: IP address, pages visited, session duration, clicks. Duration: 2 years. Data shared with Google Analytics.",
    "a5-p5": "- **Google Analytics (_gid)** (Analytical): Used by Google Analytics to distinguish users for statistical purposes. Data collected: IP address, pages visited, session duration. Duration: 24 hours. Data shared with Google Analytics.",
    "a5-p6": "- **Google Analytics (_gat)** (Analytical): Used by Google Analytics to limit the number of requests sent. Data collected: None (used for request rate limitation). Duration: 1 minute. Data shared with Google Analytics.",
    "a5-p7": "- **Google Ads (_gcl_au)** (Advertising): Used by Google Ads to track ad conversions. Data collected: Unique identifiers, interactions with ads, IP address. Duration: 90 days. Data shared with Google Ads.",

    "a6-title": "6. Cookie consent and preference management",
    "a6-p1": "Upon your first visit, a cookie banner allows you to choose which categories of cookies you wish to enable or disable.",
    "a6-p2": "By default, only essential cookies are enabled. You can choose to enable or disable functional, analytical, and advertising cookies according to your preferences.",
    "a6-p3": "You can modify or withdraw your consent at any time by accessing our cookie management interface, available at the bottom of the site or in the Application settings.",
    "a6-p4": "If you choose to disable certain cookies, some features of the application may not function properly.",

    "a7-title": "7. Data security",
    "a7-p1": "STOWY is committed to implementing all necessary technical and organizational measures to ensure the security of the personal data collected.",

    "a8-title": "8. User rights",
    "a8-p1": "- Right of access: Obtain information about your data and a copy of it.",
    "a8-p2": "- Right to rectification: Request correction of inaccurate data.",
    "a8-p3": "- Right to erasure: Request the deletion of your data.",
    "a8-p4": "- Right to restriction: Temporarily restrict the processing of your data.",
    "a8-p5": "- Right to object: Object to the processing of your data.",
    "a8-p6": "- Right to portability: Receive a copy of your data in a structured format.",

    "a9-title": "9. Changes to the privacy policy",
    "a9-p1": "STOWY reserves the right to modify this privacy policy. Changes will be communicated by email, at least one month before they come into effect.",

    "a10-title": "10. Right to lodge a complaint",
    "a10-p1": "If you disagree with the way your data is being processed, you can file a complaint with the CNIL.",

    "a11-title": "11. Effective date",
    "a11-p1": "This Privacy Policy came into effect on 10-06-2024 (MM-DD-YYYY)."
  }


};
  