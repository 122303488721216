export default {
  home: {
    'android-toast-message':
      'Téléchargez gratuitement Stowy sur le Google Play Store !',
    'android-toast-link': 'Cliquez ici !',
    'head-title': "n'en croit pas ses yeux !",
    'surname-1': 'Jude',
    'surname-2': 'Emma',
    'surname-3': 'Gabriel',
    'surname-4': 'Louise',
    'surname-5': 'Raphaël',
    'surname-6': 'Jade',
    'surname-7': 'Leo',
    'surname-8': 'Ambre',
    'head-desc-1':
      "Des dizaines d'histoires personnalisées dont vos enfants sont les héros,",
    'head-desc-2':
      "toujours à portée de main, à lire en famille comme dans un livre. Découvrez vite l'application Stowy !",
    'bubble-points-section-title':
      'Préparez-vous pour un moment familial inoubliable !',
    'bubble-points-quoted-1': 'Je suis le héros de mes propres histoires !',
    'bubble-points-quoted-1-desc':
      'Lisez à vos enfants des histoires originales dont ils sont le personnage principal, au sein de nos 4 Univers Magiques.',
    'bubble-points-quoted-2':
      "Besoin d'un temps calme ? Stowy me sauve la vie !",
    'bubble-points-quoted-2-desc':
      "Des dizaines d'histoires à portée de main, accessibles en quelques clics. L'activité parfaite pour le mercredi après-midi, les trajets en voiture, les salles d'attente, etc...",
    'bubble-points-quoted-3': 'Toutes les familles sont dans Stowy !',
    'bubble-points-quoted-3-desc':
      'Grâce à la personnalisation, Stowy est inclusif et renforce le lien entre les membres de la famille. Chaque histoire devient alors un grand moment de complicité et de partage.',
    'bubble-points-quoted-3-addon': "It's #StowyTime !",
    'how-why-1': 'Comment ça marche ?',
    'how-why-2':
      "Je télécharge l'application Stowy sur ma tablette ou mon téléphone.",
    'how-why-3': 'Je crée avec mon enfant un avatar à son image.',
    'how-why-4':
      "J'accède à de jolies histoires originales et illustrées, qui se lisent comme dans un livre.",
    'how-why-5':
      "Je m'abonne pour accéder à toutes les histoires de manière illimitée.",
    'why-trust': 'Une histoire de confiance',
    'why-pedagogic-1': 'Divertissant et pédagogique',
    'why-pedagogic-2':
      'Nous écrivons toutes nos histoires avec des Professionnels de la Petite Enfance.',
    'why-pedagogic-3':
      "Nos thématiques récurrentes sont modernes et adaptées aux enjeux de la société actuelles, telle que l'écologie, l'inclusivité, la confiance en soi.",
    'why-family-1': 'Conçu pour les familles',
    'why-family-2':
      'Stowy est une expérience conçue pour être partagée en famille. Pas de "tablette nounou", et du temps d\'écran maîtrisé et qualitatif pour les enfants...',
    'why-family-3':
      'Un éveil aux contenus digitaux de manière douce et intelligente.',
    'why-mif-1': '100% Made in France, Zéro publicité',
    'why-mif-2':
      "Nos aventures prennent vie depuis un petit village du Beaujolais. De nos jours, c'est suffisamment rare pour le mentionner :)",
    'why-mif-3':
      'Pour préserver nos enfants, Stowy est vierge de publicités. Zéro, jamais, promis !',
    'who-1': 'Qui sommes-nous ?',
    'who-2':
      "Stowy est née dans l'esprit d'un jeune papa qui a souvent l'impression de passer plus de temps à choisir l'histoire du soir qu'à en lire ! Notre mission : vous faire passer des moments inoubliables en famille. Voici l'équipe d'experts que nous avons constituée pour y parvenir.",
    'who-kevin-name': 'Kévin',
    'who-kevin-role': 'CEO',
    'who-kevin-1':
      "Après moultes expériences dans le digital, il revient ici à ses premières amours : l'innovation technique au service de l'art.",
    'who-kevin-2':
      'Il cuisine de formidables tartelettes à la banane. Demandez lui la recette, ça vaut le détour.',
    'who-seb-name': 'Sébastien',
    'who-seb-role': 'CTO',
    'who-seb-1':
      "Notre samouraï de la technique a autant d'écrans devant les yeux que d'idées à la seconde, et c'est bien grâce à lui que tout ceci est possible. Il transforme nos idées en une application qui ne bug (presque) jamais.",
    'who-seb-2':
      "Il ne distille cet art ancestral qu'aux rares élèves qui savent s'en montrer digne.",
    'who-rachel-name': 'Rachel',
    'who-rachel-role': 'Responsable Petite Enfance',
    'who-rachel-1':
      "Elle contrôle les objectifs pédagogiques, dompte les mots compliqués, met son veto quand ça fait trop peur : cette professionnelle de la Petite Enfance est notre grande gardienne, grâce à qui vos bouts de chou profitent d'histoires passionnantes en toute quiétude.",
    'who-rachel-2': "Il paraît qu'elle ne fait jamais Dodo (l'oiseau).",
    'who-lanternes-name': 'Les créatures aux lanternes',
    'who-lanternes-role': 'Petits monstres artistiques',
    'who-lanternes-1':
      "Créatures parce qu'elles créent, lanternes car elles éclairent le chemin, nos petits monstres élèvent les idées les plus farfelues en des histoires inoubliables. Leur coup de pinceau est reconnu jusqu'aux grandes pleines de l'Ouest.",
    'who-lanternes-2':
      'Fun fact : à la pleine lune, elles reprennent apparence humaine.',
    'roadmap-1': "L'application Stowy n'est pas encore tout à fait terminée.",
    'roadmap-2': 'Choisissez la formule qui ',
    'roadmap-3': 'VOUS',
    'roadmap-4': ' convient !',
    'plan-free-1': 'Formule «\u00A0Free\u00A0Kid\u00A0»',
    'plan-free-2': 'Usage illimité dans le temps, sans publicités',
    'plan-free-3': "Nombre limité d'histoires",
    'plan-free-4': 'Un seul enfant personnalisable',
    'plan-free-5': 'Gratuit',
    'plan-solo-1': 'Formule «\u00A0Solo\u00A0Kid\u00A0»',
    'plan-solo-2': 'Usage illimité dans le temps, sans publicités',
    'plan-solo-3': 'Accès illimité à toutes les histoires',
    'plan-solo-4': 'Un seul enfant personnalisable',
    'plan-solo-5': '3,99€',
    'plan-solo-6': 'TTC par mois',
    'plan-family-1': 'Formule «\u00A0Multi\u00A0Kids\u00A0»',
    'plan-family-2': 'Usage illimité dans le temps, sans publicités',
    'plan-family-3': 'Accès illimité à toutes les histoires',
    'plan-family-4': "Jusqu'à 5 enfants personnalisables !",
    'plan-family-5': '6,99€',
    'plan-family-6': 'TTC par mois',
    billedMensually: 'Facturé mensuellement, sans engagement',
    'placeholder-firstname': 'Mon prénom',
    'placeholder-lastname': 'Mon nom de famille',
    'placeholder-email': 'Mon adresse email',
    'registering-ongoing': 'Enregistrement en cours',
    'registering-done': 'Merci de votre soutien !',
    'checkout-ongoing':
      'Communication avec notre prestataire de paiement sécurisé...',
    'checkout-pending': 'Lien de paiement sécurisé créé',
    'cookie-usage-1':
      'Nous utilisons les cookies pour vous garantir la meilleure expérience possible.',
    'cgv-checkbox-1': "J'accepte",
    'cgv-checkbox-2': 'les conditions générales de vente.',
    'contact-address': 'Contact: kevin[at]stowy[.]fr',
    'copyright-title': '© 2024 - Stowy',
    'copyright-1': 'Stowy SAS au capital de 10,000 €',
    'copyright-2': '878 788 419 R.C.S.',
    'cookies-title': '🍪 Cookies',
    'cookies-intro':
      "Nous utilisons des cookies pour améliorer votre expérience sur notre site et analyser notre trafic. Vous pouvez choisir d'accepter tous les cookies, de les refuser, ou de personnaliser vos préférences en cliquant sur 'Gérer mes choix'. Comme leur nom l'indique, les cookies 'Essentiels' sont essentiels au fonctionnement du site, ils ne peuvent donc pas être refusés.",
    'cookies-option-1': 'Essentiels',
    'cookies-option-2': 'Analytiques',
    'cookies-option-3': 'Publicitaires',
    'cookies-button-configure': 'Gérer mes choix',
    'cookies-button-accept': 'Tout accepter',
    'cookies-button-refuse': 'Tout refuser',
    'cookies-essentials-title': 'Essentiels',
    'cookies-essentials-desc':
      "Ils sont strictement nécessaires pour naviguer sur le web, permettant des fonctions de base telles que la navigation d'une section à l'autre. Ils gèrent des aspects techniques tels que l'identification des profils ou des sessions et la gestion des préférences d'outils dans l'utilisation, l'interaction et la localisation de l'accès au contenu. Les cookies obligatoires utilisés sur ce site sont les suivants :",
    'cookies-essentials-stowy-desc-1': 'Stowy',
    'cookies-essentials-stowy-desc-2':
      "Nous utilisons des cookies afin d'analyser vos habitudes de navigation, vous proposer des contenus et services adaptés à vos centres d'intérêt et réaliser des statistiques d'utilisation de notre site web, en d'autres mots : afin d'améliorer notre site et votre expérience utilisateur.",
    'cookies-essentials-stowy-desc-3':
      'Les balises suivantes sont nécessaires au bon fonctionnement de notre site web.',
    'cookies-essentials-stowy-desc-4': 'Objectif :',
    'cookies-essentials-stowy-desc-5':
      "Les données collectées servent à identifier l'utilisateur, à se connecter, à passer et visualiser les commandes.",
    'cookies-essentials-stowy-desc-6': 'Technologies utilisées :',
    'cookies-essentials-stowy-desc-7': 'Cookies',
    'cookies-essentials-stowy-desc-8': 'Stockage local',
    'cookies-essentials-stowy-desc-9': 'Données collectées',
    'cookies-essentials-stowy-desc-10':
      'Informations de commande : nom, prénom, email et adresse de livraison.',
    'cookies-essentials-stowy-desc-11':
      'ID utilisateur pour garder la session active.',
    'cookies-essentials-gtm-desc-1': 'Google Tag Manager',
    'cookies-essentials-gtm-desc-2':
      "Il s'agit d'un système de gestion des balises pour gérer des balises JavaScript et HTML utilisées pour le suivi et l'analyse de votre site web.",
    'cookies-essentials-gtm-desc-3':
      'Les balises suivantes sont nécessaires au bon fonctionnement de notre site web.',
    'cookies-essentials-gtm-desc-4': 'Objectif :',
    'cookies-essentials-gtm-desc-5': 'Gestion des balises du site web',
    'cookies-essentials-gtm-desc-6': 'Technologies utilisées :',
    'cookies-essentials-gtm-desc-7': 'Cookies',
    'cookies-essentials-gtm-desc-8': 'Données collectées',
    'cookies-essentials-gtm-desc-9': "Aucune donnée n'est collectée.",
    'cookies-functionals-title': 'Fonctionnelles',
    'cookies-functionals-ga-desc-1': 'Google Analytics',
    'cookies-functionals-ga-desc-2':
      "Ces balises nous permettent d'analyser l'utilisation de notre site web afin de mesurer et d'améliorer sa performance.",
    'cookies-functionals-ga-desc-3':
      'Les balises suivantes sont nécessaires au bon fonctionnement de notre site web.',
    'cookies-functionals-ga-desc-4': 'Objectif :',
    'cookies-functionals-ga-desc-5': 'Analyses et optimisation',
    'cookies-functionals-ga-desc-6': 'Technologies utilisées :',
    'cookies-functionals-ga-desc-7': 'Cookies',
    'cookies-functionals-ga-desc-8': 'Données collectées :',
    'cookies-functionals-ga-desc-9':
      'URL référent, sous-pages visitées, fréquence et durée des visites des sous-pages.',
    cookies: {
      essential: {
        description:
          "Les cookies essentiels sont nécessaires au bon fonctionnement de l'application, tels que la gestion de la session utilisateur et du consentement. Ils ne peuvent pas être désactivés.",
        list: {
          session: {
            name: 'Session Cookie',
            description: "Maintient la session active pour l'utilisateur",
            data: "Identifiant de session, état d'authentification",
            duration: 'Durée : Session',
          },
          consent: {
            name: 'Consent Cookie',
            description:
              "Enregistre les préférences de consentement de l'utilisateur",
            data: 'Préférences de consentement',
            duration: 'Durée : 12 mois',
          },
          app: {
            name: 'Cookies applicatifs',
            description:
              "Utilisés pour enregistrer la configuration de votre personnage, l’apparition du tutoriel, et les données d'histoire.",
            data: "Données d'application (Local storage)",
            duration: 'Durée : Indéfinie',
          },
        },
      },
      analytics: {
        description:
          "Les cookies analytiques permettent d'analyser le trafic et d'améliorer l'application. Ils sont activés uniquement après consentement.",
        list: {
          _ga: {
            name: 'Google Analytics (_ga)',
            description:
              'Utilisé pour distinguer les utilisateurs et suivre le trafic du site',
            data: 'Adresse IP, pages visitées, durée des sessions, clics',
            duration: 'Durée : 2 ans',
          },
          _gid: {
            name: 'Google Analytics (_gid)',
            description:
              'Utilisé pour distinguer les utilisateurs à des fins statistiques',
            data: 'Adresse IP, pages visitées, durée des sessions',
            duration: 'Durée : 24 heures',
          },
          _gat: {
            name: 'Google Analytics (_gat)',
            description: 'Utilisé pour limiter le nombre de requêtes envoyées.',
            data: 'Aucune donnée collectée',
            duration: 'Durée : 1 minute',
          },
        },
      },
      advertising: {
        description:
          'Les cookies publicitaires sont utilisés pour personnaliser les annonces et mesurer leur efficacité. Ils sont activés uniquement après consentement.',
        list: {
          _gcl_au: {
            name: 'Google Ads (_gcl_au)',
            description: 'Utilisé pour suivre les conversions des annonces',
            data: 'Identifiants uniques, interactions avec les annonces, adresse IP',
            duration: 'Durée : 90 jours',
          },
        },
      },
    },
    'cookies-button-accept-all': 'Tout accepter',
    'cookies-button-accept-selection': 'Accepter la sélection',
  },

  cgu: {
    title: "Conditions Générales d'Utilisation",
    p1: 'L’utilisation de l’activité "STOWY" vaut acceptation sans réserve des présentes Conditions Générales d’Utilisation (ci-après les "CGU").',
    p2: 'Le présent contrat (ci-après le "Contrat") constitue les conditions générales d\'utilisation entre vous personne physique ou morale unique (ci-après "l\'Utilisateur") et la société STOWY (ci-après “L\'Éditeur”).',
    'a1-title': 'ARTICLE 1 - DÉFINITIONS',
    'a1-p1':
      'Les termes qui seront définis ci-dessous auront la signification suivante :',
    'a1-p2':
      "\"CGU\": désigne les Conditions Générales d'Utilisation qui régissent l'utilisation de l'application mobile STOWY et du site internet www.stowy.fr, et qui sont accessibles à l’adresse suivante : www.stowy.fr/cgu-cgv ;",
    'a1-p3':
      '"Application": désigne l\'application mobile conçue, développée et exploitée par STOWY qui est téléchargeable sur la plateforme de téléchargement d\'applications pour téléphones mobiles et tablettes ;',
    'a1-p4':
      '"Site": désigne le site Internet conçu, développé et exploité par STOWY et dont l\'adresse url est la suivante: www.stowy.fr ;',
    'a1-p5':
      '"Utilisateur": désigne toute personne utilisant STOWY via l\'application mobile ;',
    'a1-p6':
      '"L\'Éditeur": désigne la société STOWY SAS, domiciliée au 50 Chemin des Caillots, 71670 Saint Firmin, immatriculée au RCS de Chalon-sur-Saône sous le numéro 898788419 ;',
    'a1-p7':
      'Le "Contenu": désigne les données mises à disposition par STOWY via l’application.',
    'a2-title': 'ARTICLE 2 - OBJET',
    'a2-p1':
      "Les présentes constituent, au 01/10/2024, les Conditions Générales d’Utilisation de l'Application STOWY.",
    'a2-p2': "Elles s'appliquent à l'exclusion de toutes autres conditions.",
    'a2-p3':
      "L’Éditeur de l'Application se réserve le droit de modifier les CGU à tout moment. En cas de modification, l'Utilisateur en sera informé par email, à l’adresse communiquée à l'Éditeur lors de son enregistrement, au moins un (1) mois avant l’entrée en vigueur des nouvelles CGU. Si l'Utilisateur n'a pas créé de compte ou s'il ne peut plus accéder à l'adresse email qu'il a fournie, il lui incombe de consulter régulièrement la version la plus récente des CGU sur le Site.",
    'a2-p4':
      'Les CGU applicables sont donc celles en vigueur à la date d’inscription de l’Utilisateur et lors de chaque utilisation, sous réserve des modifications.',
    'a2-p5':
      'En conséquence, l’Utilisateur est invité à consulter régulièrement l’Application ou ses emails pour se tenir informé des évolutions des CGU.',
    'a3-title': 'ARTICLE 3 - ACCÈS À STOWY',
    'a3-p1':
      "L'accès à l'application STOWY est gratuit et limité dans le cadre d’une offre gratuite appelée \"Free Kid\". L'application offre un accès restreint à un certain nombre d'histoires pour un seul enfant, sans contrepartie financière.",
    'a3-p2':
      "Pour accéder à l'intégralité des histoires et fonctionnalités, un abonnement payant est nécessaire. STOWY se réserve le droit de modifier ou de restreindre l'accès à l'offre \"Free Kid\" sans préavis.",
    'a4-title': "ARTICLE 4 - MODIFICATION DE L'APPLICATION",
    'a4-p1':
      "L’Éditeur de l'Application se réserve le droit, à tout moment, de modifier ou d'interrompre temporairement ou définitivement l'Application, sans préavis et sans que sa responsabilité puisse être engagée. Ces modifications peuvent notamment concerner des mises à jour techniques, des améliorations de fonctionnalités ou des impératifs légaux. L'Utilisateur reconnaît qu'aucune indemnisation ne pourra être réclamée à ce titre.",
    'a4-p2':
      'Dans le cadre de l’offre gratuite (“Free Kid”), les histoires disponibles gratuitement ainsi que leur nombre peuvent changer régulièrement et aléatoirement, sans que l’Utilisateur en soit nécessairement informé à l’avance.',
    'a5-title': "ARTICLE 5 - OBLIGATIONS DE L'UTILISATEUR",
    'a5-p1':
      "L'Utilisateur s'engage à respecter les présentes CGU, ainsi que l’ensemble des lois et réglementations en vigueur en France ou dans son pays de résidence.",
    'a5-p2':
      "- Respecter les droits de propriété intellectuelle afférents aux contenus fournis par le Site et l'Application ;",
    'a5-p3':
      "- Ne pas utiliser l'Application à des fins contraires à la loi, telles que le racolage ou la prostitution ;",
    'a5-p4':
      "- Ne pas tenir ou proférer des propos ou diffuser sous quelque forme que ce soit des contenus contrevenant aux droits d'autrui, à caractère diffamatoire, injurieux, obscène, offensant, violent, incitant à la haine, ou tout contenu contraire aux lois, règlements, ou aux bonnes mœurs ;",
    'a5-p5':
      "- Ne pas poster ou diffuser des informations ou contenus susceptibles de perturber ou d’empêcher l’utilisation normale de l'Application, tels que des logiciels malveillants, virus, bombes logiques, ou envois massifs de messages ;",
    'a5-p6':
      "- Ne pas interrompre, suspendre, ralentir ou empêcher le bon fonctionnement du Site ou de l'Application ;",
    'a5-p7':
      "- Ne pas tenter des intrusions dans les systèmes de la Société ou procéder à tout acte de piratage, y compris le désassemblage, l'analyse ou la reproduction du code source de l'Application ;",
    'a5-p8':
      "- Ne pas détourner les ressources système du Site ou de l'Application à des fins autres que celles pour lesquelles ils ont été conçus ;",
    'a5-p9':
      "- Ne pas imposer une charge disproportionnée sur les infrastructures du Site ou de l'Application ;",
    'a5-p10':
      '- Ne pas porter atteinte aux mesures de sécurité et d’authentification mises en place par la Société ;',
    'a5-p11':
      "- Ne pas accéder ou tenter d’accéder à des ressources techniques ou contenus dont l'accès n'est pas explicitement prévu dans son abonnement, par quelque moyen que ce soit ;",
    'a5-p12':
      '- Ne pas porter atteinte aux droits et intérêts financiers, commerciaux, moraux ou à la réputation de la Société ou de ses utilisateurs ;',
    'a5-p13':
      "- Ne pas monnayer, vendre ou concéder tout ou partie de l’accès au Site, à l'Application, ou aux informations qu’ils contiennent.",
    'a6-title': 'ARTICLE 6 - DISPONIBILITÉ DU SERVICE',
    'a6-p1':
      "L'Application est accessible par l'Utilisateur 24 heures sur 24, 7 jours sur 7, toute l'année. L'Éditeur de l'Application se réserve néanmoins le droit, sans préavis ni indemnité, d'en fermer temporairement ou définitivement l'accès, et ne pourra être tenu responsable des dommages de toute nature pouvant survenir de ce fait.",
    'a7-title': 'ARTICLE 7 - PUBLICITÉ',
    'a7-p1':
      "L'application fonctionne sans aucunes publicités. Néanmoins, la Société se réserve le droit d’insérer sur toute page du Site tous messages publicitaires ou promotionnels sous une forme et dans des conditions dont la Société sera seule juge.",
    'a8-title': 'ARTICLE 8 - DÉSINSCRIPTION',
    'a8-p1':
      "L'Utilisateur peut à tout moment choisir de se désinscrire du service STOWY. La procédure de désinscription dépend du fait que l'Utilisateur dispose ou non d'un compte.",
    'a8-p2':
      "Si l'Utilisateur ne possède pas de compte : Il doit se rendre dans l'application STOWY, cliquer sur le bouton des paramètres situé en bas à droite de l'écran, représenté par un logo d'un adulte avec un enfant, puis sélectionner l'option \"Supprimer mes informations\". Cette action entraînera la suppression des informations personnelles liées à l'utilisation de l'application.",
    'a8-p3':
      'Si l\'Utilisateur possède un compte : Il doit accéder à son espace personnel via le Site à l’adresse http://www.stowy.fr/account, se connecter avec ses identifiants, puis se rendre dans l\'onglet "Abonnement" et cliquer sur "Se désinscrire". Cette action entraînera la désactivation de son compte et la suppression de son accès aux services de l\'application.',
    'a8-p4':
      "Conservation des données : Conformément à la législation en vigueur, seules les données commerciales strictement nécessaires (telles que l'identité de l'acquéreur, les détails des paiements, etc.) seront conservées par l'Éditeur pendant une durée de dix (10) ans à compter de la date de désinscription, dans le cadre de ses obligations légales et comptables.",
    'a9-title': 'ARTICLE 9 - DROIT APPLICABLE - ATTRIBUTION DE JURIDICTION',
    'a9-p1':
      "Ces Conditions d'Utilisation sont régies, interprétées et appliquées conformément au droit français, la langue d'interprétation étant la langue française en cas de contestation sur la signification d'un terme ou d'une disposition des Conditions d'Utilisation.",
    'a10-title':
      "ARTICLE 10 - UTILISATION DE L'APPLICATION PAR DES ENFANTS ET RESPONSABILITÉ DES REPRÉSENTANTS LÉGAUX",
    'a10-p1':
      "L'Application STOWY est conçue pour être utilisée par des enfants sous la surveillance et la responsabilité exclusive des parents ou des représentants légaux. L'Éditeur rappelle que l'utilisation d'écrans par les enfants doit se faire conformément aux recommandations officielles, telles que celles établies par les autorités sanitaires, l'État, ou les professionnels de la santé, notamment les principes du \"3-6-9-12\".",
    'a10-p2':
      "L'Éditeur décline toute responsabilité en cas de dommages, retards de développement, troubles du comportement, ou tout autre préjudice, direct ou indirect, pouvant être causé à un enfant en lien avec l’utilisation de l'Application. Il incombe aux parents ou aux représentants légaux de surveiller et de réguler l'utilisation de l'Application par l'enfant, en veillant à respecter les recommandations médicales et les bonnes pratiques relatives à l'exposition aux écrans.",
    'a10-p3':
      "- Limiter le temps d'écran des enfants conformément aux recommandations des médecins et des autorités compétentes ;",
    'a10-p4':
      "- Surveiller toute utilisation anormale ou comportement suspect lié à l’utilisation de l'Application, et, en cas de doute, d’interrompre immédiatement l'accès à l'Application ;",
    'a10-p5':
      "- Ne pas autoriser l'utilisation de l'Application tard le soir, pendant la nuit, ou à des moments où cela pourrait perturber le rythme de sommeil des enfants ;",
    'a10-p6':
      "- Accompagner l'utilisation de l'Application pour favoriser une expérience encadrée, enrichissante et adaptée à l'âge de l'enfant ;",
    'a10-p7':
      "- S'assurer que le contenu de l'Application est consulté dans un environnement sain et dans des conditions favorables au bien-être de l'enfant.",
    'a10-p8':
      "L'Éditeur ne saurait en aucun cas être tenu responsable des effets liés à une utilisation excessive ou inappropriée de l'Application, ni des conséquences sur le bien-être physique ou mental de l'enfant. La responsabilité de la surveillance et du contrôle de l'utilisation de l'Application par les enfants incombe exclusivement aux parents ou aux représentants légaux.",
    'a11-title': 'ARTICLE 11 - ENTRÉE EN VIGUEUR',
    'a11-p1': 'Les présentes CGU sont entrées en vigueur le 06/10/2024.',
  },
  cgv: {
    title: 'Conditions Générales de Vente',
    p1: "Les présentes conditions générales de vente (CGV) régissent l'utilisation de l'application mobile STOWY et du site internet http://www.stowy.fr, et sont accessibles à l'adresse suivante : http://www.stowy.fr/cgu-cgv.",
    'a1-title': 'ARTICLE 1 - DÉFINITIONS',
    'a1-p1':
      'Les termes qui seront définis ci-dessous auront la signification suivante :',
    'a1-p2':
      '- "CGV": désigne les Conditions Générales de Vente qui régissent l\'utilisation de l\'application mobile STOWY et du site internet http://www.stowy.fr, et qui sont accessibles à l’adresse suivante : http://www.stowy.fr/cgu-cgv.',
    'a1-p3':
      '- "Application": désigne l\'application mobile conçue, développée et exploitée par STOWY qui est téléchargeable sur la plateforme de téléchargement d\'applications pour téléphones mobiles et tablettes.',
    'a1-p4':
      '- "Site": désigne le site Internet conçu, développé et exploité par STOWY et dont l\'adresse URL est la suivante : http://www.stowy.fr.',
    'a1-p5':
      '- "l\'Acheteur" : désigne toute personne physique ou morale, agissant soit en tant que consommateur, soit en tant que professionnel, et procédant à l\'achat de Produits proposés par la Société sur le Site.',
    'a1-p6':
      '- "La Société" : désigne la société STOWY dont les informations détaillées sont précisées à l’Article 3 des présentes CGV.',
    'a1-p7':
      '- Le "Contenu" : désigne les données mises à disposition par STOWY via l’application.',
    'a1-p8':
      '- "Les Produits": désigne les abonnements, livres, cahiers de jeux, produits dérivés, ou tout autre produit et service, matériel ou immatériel, vendu en ligne par la Société sur le Site.',
    'a2-title': 'ARTICLE 2 - OBJET',
    'a2-p1':
      "Les présentes conditions générales de vente s'appliquent sans restriction ni réserve à l'ensemble des ventes en ligne proposées par la société STOWY sur le Site.",
    'a2-p2':
      "Les présentes conditions générales ont pour objet de définir les modalités et conditions de la vente et de la livraison des Produits, ainsi que de définir les droits et obligations des parties dans ce cadre. Elles sont accessibles et imprimables à tout moment par un lien direct et permanent sur le Site. Les présentes conditions générales prévalent sur toutes autres conditions générales ou particulières, y compris celles de l'acheteur, à moins qu'elles n'aient été expressément acceptées par écrit par STOWY.",
    'a2-p3':
      "L’Éditeur de l'Application se réserve le droit de modifier les CGV à tout moment. En cas de modification, l'Utilisateur en sera informé par email, à l’adresse communiquée à l'Éditeur lors de son enregistrement, au moins un (1) mois avant l’entrée en vigueur des nouvelles CGV. Si l'Utilisateur ne peut plus accéder à l'adresse email qu'il a fournie, il lui incombe de consulter régulièrement la version la plus récente des CGV sur le Site.",
    'a2-p4':
      'Les CGV applicables sont donc celles en vigueur à la date d’inscription de l’Utilisateur et lors de son dernier paiement ou échéance de paiement, sous réserve des modifications.',
    'a2-p5':
      'En conséquence, l’Utilisateur est invité à consulter régulièrement l’Application ou ses emails pour se tenir informé des évolutions des CGV.',
    'a2-p6':
      'Les CGV peuvent être complétées le cas échéant par des conditions de vente ou d’utilisation particulières à certains Produits ou services, lesquelles complètent les présentes conditions générales et, en cas de contradiction, prévalent sur ces dernières.',
    'a2-p7':
      'Dans l’hypothèse d’une traduction des présentes conditions générales dans une ou plusieurs langues, la langue d’interprétation sera la langue française en cas de contradiction ou de contestation sur la signification d’un terme ou d’une disposition.',
    'a3-title': 'ARTICLE 3 - IDENTITÉ DU VENDEUR ET CONTACT',
    'a3-p1':
      'Le Site et l’Application sont édités et exploités par la société STOWY, Société par Actions Simplifiée au capital de 10 000 euros, immatriculée au RCS de CHALON SUR SAONE sous le n° 898 788 419.',
    'a3-p2':
      "La Société a pour objet, en France comme à l’étranger, les activités d'édition de logiciels informatiques, applications web ou mobiles, ainsi que la conception, développement, adaptation, test, prise en charge et maintenance de logiciels informatiques et applications web ou mobiles.",
    'a3-p3':
      'La Société peut également commercialiser, vendre, louer et mettre à disposition à titre onéreux ou non des logiciels et applications.',
    'a3-p4': '(ci-après : la "Société").',
    'a3-p5':
      'La Société peut être contactée à l’adresse email suivante : kevin [at] stowy.fr.',
    'a4-title': 'ARTICLE 4 - ACCEPTATION DES CONDITIONS GÉNÉRALES DE VENTE',
    'a4-p1':
      'L’Acheteur déclare, préalablement à sa commande, qu’il a la pleine capacité juridique, lui permettant de s’engager au titre des présentes conditions générales.',
    'a4-p2':
      'Son acceptation de celles-ci est matérialisée par une case à cocher dans le formulaire de commande. Cette acceptation ne peut être que pleine et entière.',
    'a4-p3':
      'Toute acceptation sous réserve est considérée comme nulle et non avenue.',
    'a4-p4':
      'L’Acheteur qui n’accepte pas d’être lié par les présentes conditions générales ne doit pas effectuer de commande sur le Site.',
    'a5-title': 'ARTICLE 5 - CARACTÉRISTIQUES DES PRODUITS',
    'a5-p1':
      'Avant toute commande, l’Acheteur s’engage à prendre connaissance, sur le Site, de l’ensemble des informations liées aux Produits proposés.',
    'a5-p2':
      "L'Acheteur est invité à consulter les descriptions détaillées des Produits disponibles sur le Site afin de s'assurer qu'ils répondent à ses besoins et contraintes spécifiques.",
    'a5-p3':
      "La Société ne saurait être tenue responsable, et aucun remboursement ne sera effectué, en cas d'utilisation inappropriée des Produits ou d'incompatibilité technique.",
    'a5-p4':
      "Les Produits proposés, qu'ils soient matériels ou immatériels, sont conformes aux normes en vigueur sur le territoire français et à la législation communautaire.",
    'a5-p5':
      'La disponibilité des Produits est indiquée dans la limite des stocks ou des capacités techniques disponibles.',
    'a5-p6':
      "Les photographies, les représentations et les descriptions des Produits sur le Site sont les plus précises possibles, mais n'engagent la Société que pour ce qui est décrit de manière explicite.",
    'a6-title': 'ARTICLE 6 - COMMANDE',
    'a6-1-title': '6.1 Passation de la Commande',
    'a6-1-p1':
      'Pour passer commande, l’Acheteur doit sélectionner les Produits de son choix et payer par carte bancaire via notre partenaire de paiement Stripe.',
    'a6-1-p2':
      'Dans le cadre de sa commande, l’Acheteur est invité à fournir ses coordonnées aux fins de facturation.',
    'a6-1-p3':
      'Il doit remplir l’ensemble des champs marqués comme obligatoires dans le formulaire prévu à cet effet.',
    'a6-1-p4':
      'Les commandes qui ne comportent pas l’ensemble des informations requises ne peuvent pas être validées.',
    'a6-1-p5':
      'L’Acheteur garantit que toutes les informations qu’il donne dans le formulaire de commande sont exactes, à jour et sincères et ne sont entachées d’aucun caractère trompeur.',
    'a6-1-p6':
      'Il est informé et accepte que ces informations valent preuve de son identité et l’engagent dès leur validation.',
    'a6-2-title': '6.2 Confirmation de commande',
    'a6-2-p1':
      'À l’issue de sa commande, l’Acheteur reçoit par email une confirmation de celle-ci.',
    'a6-2-p2':
      'L’Acheteur doit s’assurer que les coordonnées qu’il a communiquées lors de sa commande ou de la mise à jour de son Compte sont correctes et qu’elles lui permettent de recevoir l’email de confirmation de sa commande.',
    'a6-2-p3':
      'A défaut de réception de celui-ci, l’Acheteur doit contacter la Société aux coordonnées mentionnées à l’article 2.',
    'a6-2-p4':
      'La Société recommande à l’Acheteur de conserver les informations contenues dans la confirmation de commande.',
    'a6-2-p5':
      'La commande et sa confirmation sont considérées comme reçues lorsque les parties auxquelles elles sont adressées peuvent y avoir accès.',
    'a6-3-title': '6.3 Modalités de renouvellement d’un abonnement',
    'a6-3-p1':
      "L'Utilisateur peut à tout moment notifier à l'Éditeur son souhait de résilier son Abonnement sans frais autres que ceux éventuellement liés à la transmission de sa demande.",
    'a6-3-p2':
      "La résiliation prendra effet à la date d'échéance de l'Abonnement en cours, à la condition que la notification soit faite par l'Utilisateur au plus tard 48 h avant la date d'échéance de l'abonnement en cours, conformément aux dispositions de l'ARTICLE 16 ci-dessous 'Résiliation'.",
    'a6-3-p3':
      "À l'expiration d'un Abonnement, celui-ci sera, sauf résiliation notifiée par l'Utilisateur à l'Éditeur avant l'échéance de l'Abonnement en cours dans les conditions indiquées, renouvelé par périodes équivalentes à celle initialement choisie par l'Utilisateur.",
    'a6-3-p4':
      "L'abonnement prorogé sera facturé à l'Utilisateur sur la base tarifaire et la périodicité de l'abonnement initialement souscrits.",
    'a6-3-p5':
      "L'Utilisateur pourra résilier à tout moment l'abonnement prorogé pour une durée indéterminée, qui prendra alors fin à l'issue de la période d'abonnement en cours à la condition que la notification soit faite par l'Utilisateur à l'Éditeur au plus tard 48 h avant la date d'échéance de l'Abonnement en cours.",
    'a6-3-p6':
      "Conformément à l'article L 136-1 du Code de Consommation français, l’éditeur informera l’utilisateur concerné en lui indiquant, par courrier électronique, envoyé au plus tard trente (30) jours avant le terme extinctif de l'Abonnement initial, qu'il peut résilier gratuitement son Abonnement avant la date d'échéance de celui-ci en le notifiant à l’éditeur, faute de quoi, son Abonnement sera prorogé par périodes équivalentes à celle initialement choisie par l’utilisateur jusqu'à ce que l’utilisateur notifie à l’éditeur son souhait de résilier son Abonnement en cours dans les conditions décrites à l’Article 7 'Résiliation' des présentes CGV.",
    'a6-4-title': '6.4 Utilisation du compte',
    'a6-4-p1':
      "L'Utilisateur reconnaît que les abonnements souscrits sont strictement personnels et ne peuvent en aucun cas être partagés avec des tiers, que ce soit de manière gratuite ou payante.",
    'a6-4-p2':
      "L'utilisation d'un compte par plusieurs personnes ou la transmission des identifiants d'accès à des tiers est formellement interdite.",
    'a6-4-p3':
      "En cas de constatation d'un partage de compte, la Société se réserve le droit de suspendre ou de résilier l'abonnement de l'Utilisateur sans préavis, conformément aux dispositions de l'Article 7 - Résiliation.",
    'a6-4-p4':
      "Cette mesure pourra être prise sans que l'Utilisateur puisse prétendre à un remboursement pour la période d'abonnement restant.",
    'a7-title': 'ARTICLE 7 - RESILIATION',
    'a7-p1':
      "Pour résilier son Abonnement, l'Utilisateur doit se rendre sur http://www.stowy.fr/account, se connecter à son compte, puis cliquer sur l'onglet 'Abonnement'.",
    'a7-p2':
      "L'Utilisateur peut alors soit choisir l'option 'Changer d'offre' pour passer à l'offre gratuite 'Free Kid', soit décider de se désinscrire totalement en sélectionnant 'Me désinscrire'.",
    'a7-p3':
      "Cette désinscription entraînera la désactivation du compte et la suppression de l'accès aux services de l'Application.",
    'a7-p4':
      "La résiliation d'un Abonnement prendra effet à la date d'expiration de l'Abonnement en cours à condition que la notification soit effectuée au moins 48 heures avant la fin de la période de facturation.",
    'a7-p5':
      "En cas de résiliation par l'Utilisateur, aucun remboursement des périodes non consommées ne sera effectué.",
    'a7-p6':
      "En cas de manquement grave par l'Utilisateur aux présentes CGV ou aux CGU, l'Éditeur se réserve le droit de résilier le compte de l'Utilisateur sans préavis.",
    'a7-p7':
      "Cette résiliation aura les mêmes effets qu'une résiliation à l'initiative de l'Utilisateur, à l’exception que l’interruption de service pourra être immédiate.",
    'a7-p8':
      "L'Utilisateur sera informé par email de la résiliation de son compte.",
    'a7-p9':
      "Les données relatives à l'Utilisateur seront supprimées ou conservées selon les délais légaux après la résiliation du compte.",
    'a8-title': 'ARTICLE 8 - PRIX ET MODALITÉS DE PAIEMENT',
    'a8-p1-title': '8.1 Prix',
    'a8-p1-1':
      'Dans le cadre d’une commande en ligne, les prix de vente des Produits sont indiqués sur le Site. Ils sont indiqués en euros, toutes taxes françaises comprises (TVA française et autres taxes applicables). Ils comprennent les frais de livraison pour certains produits liés à l’abonnement.',
    'a8-p1-2':
      'Dans le cas de l’intégration d’un service de livraison, le montant des frais applicables sera indiqué avant la validation de la commande par l’Acheteur. Le prix applicable est celui affiché sur le Site au moment de l’enregistrement de la commande de l’Acheteur.',
    'a8-p1-3':
      "L'Application est gratuite au téléchargement avec un accès limité aux histoires et aux fonctionnalités. Pour profiter en illimité de toutes les histoires, l'Utilisateur devra souscrire à un abonnement s'il souhaite conserver l'accès à toutes les histoires. Le coût de l'abonnement mensuel sera de 3,99€ par mois pour un seul enfant (Formule 'Solo Kid'), ou 5,99€ pour un nombre illimité d'enfants (Formule 'Multi Kids'). Le prix pourra être différent en période de promotion.",
    'a8-p1-4':
      "L'Éditeur de l'Application se réserve le droit de modifier les prix des abonnements à tout moment. En cas de modification des tarifs, les Utilisateurs abonnés seront informés par email au moins un (1) mois avant l'application des nouveaux prix. L'Utilisateur aura alors la possibilité de résilier son abonnement s'il ne souhaite pas accepter les nouveaux tarifs. En l'absence de résiliation, les nouveaux prix seront automatiquement appliqués à partir de la prochaine période de facturation.",
    'a8-p2-title': '8.2 Modalités de paiement',
    'a8-p2-1':
      'La totalité du prix des Produits et des frais de livraison applicables sont exigibles à la commande. Leur paiement peut s’effectuer en ligne par carte bancaire ou par tout autre moyen qui sera proposé sur le Site au moment de la commande. L’Acheteur garantit à la Société qu’il dispose des autorisations nécessaires pour utiliser le mode de paiement choisi. La Société se réserve le droit de suspendre ou d’annuler toute commande et/ou livraison, en cas de non-paiement de toute somme qui serait due par l’Acheteur, en cas d’incident de paiement, ou en cas de fraude ou tentative de fraude.',
    'a8-p3-title': '8.3 Propriété',
    'a8-p3-1':
      "La Société conserve la propriété pleine et entière des Produits physiques vendus jusqu’à l'encaissement total du prix.",
    'a8-p3-2':
      "En ce qui concerne les Produits immatériels, tels que le code source de l’Application et ses Contenus accessibles par abonnement, STOWY demeure le propriétaire exclusif de l'ensemble du Contenu. L'Acheteur ne dispose que d'un droit d'usage personnel, non exclusif et non transférable, dans le cadre de son abonnement, sans possibilité de reproduction, modification, ou exploitation à des fins commerciales.",
    'a8-p4-title': '8.4 Gestion des abonnements et des paiements échoués',
    'a8-p4-1':
      "Les abonnements souscrits par l'Utilisateur sont gérés par Stripe, notre partenaire de paiement. En cas de défaut de paiement, plusieurs tentatives automatiques de prélèvement seront effectuées. Si, après plusieurs tentatives, le paiement échoue toujours, l'abonnement de l'Utilisateur sera suspendu, et son accès aux fonctionnalités premium de l'Application sera bloqué.",
    'a8-p4-2':
      "En cas d'échec de paiement, l'Utilisateur recevra des notifications par email lui rappelant de mettre à jour ses informations de paiement. L'Utilisateur dispose d'un délai avant la suspension de son compte pour régulariser la situation. En l'absence de régularisation, l'accès à l'Application sera bloqué, bien que l'Utilisateur puisse toujours accéder à son compte via la page http://www.stowy.fr/account pour réactiver son abonnement.",
    'a8-p4-3':
      "Dès que le paiement sera régularisé, l'accès aux fonctionnalités premium sera rétabli automatiquement, sans nécessiter de reconnexion. Si l'Utilisateur souhaite se désinscrire, il peut suivre les étapes décrites à l’Article 7 - Résiliation. Une fois désabonné, l'Utilisateur conservera l'accès à son abonnement jusqu'à la fin de la période en cours, sans nouvelle facturation.",
    'a9-title': 'ARTICLE 9 - DROIT DE RÉTRACTATION',
    'a9-p1':
      'Dans le cadre de l’achat de produits physiques, l’Acheteur dispose d’un délai de sept jours francs à compter de la date de réception des Produits commandés pour se rétracter sans avoir à justifier de motifs ni à payer de pénalités, à l’exception des frais de retour. Il doit prendre contact avec la Société par courrier avec accusé de réception au 50 Chemin des Caillots, 71670 SAINT FIRMIN, afin de l’informer de sa volonté de faire usage de son droit de rétractation. Le courrier doit mentionner : le numéro de commande, le nom de L’Acheteur, la date de la commande et le motif de la rétractation. L’Acheteur retournera alors les Produits à la Société par tout moyen utile. Les Produits doivent impérativement être retournés à la Société dans leur emballage d’origine non ouvert, avec l’ensemble de leurs accessoires, sans trace d’utilisation. Ils doivent être accompagnés d’une copie de la facture d’achat correspondante. L’Acheteur sera remboursé dans les meilleurs délais et au plus tard dans les 30 (trente) jours suivant la date de réception des Produits retournés de l’intégralité des sommes versées pour sa commande, déduction faite des frais de retour et des produits manquants. Aucune annulation de commande ne pourra intervenir en dehors de l’exercice du droit de rétractation selon les modalités ci-dessus.',
    'a9-p2':
      "Concernant l’achat de Produits immatériels (tels que les abonnements ou les contenus numériques), l’Acheteur renonce expressément à tout droit de rétractation dès lors qu'il accède ou commence à utiliser le produit de manière immédiate, conformément à l’article L221-28 du Code de la consommation. En acceptant les présentes CGV, l'Acheteur reconnaît que son droit de rétractation ne peut être exercé une fois l’accès au contenu numérique initié ou consommé.",
    'a10-title': 'ARTICLE 10 - GARANTIES LÉGALES',
    'a10-p1':
      'L’Acheteur bénéficie des garanties légales de conformité ainsi que des garanties contre les vices cachés de la chose vendue, conformément aux articles 1641 et suivants du Code civil et aux articles L217-4 et suivants du Code de la consommation. Si l’Acheteur constate que le Produit livré présente un défaut, une non-conformité ou est endommagé, il doit en informer la Société aux coordonnées mentionnées à l’Article 2 des présentes, en précisant la nature du défaut, de la non-conformité ou du dommage constaté, dans un délai raisonnable suivant la découverte du problème.',
    'a10-p2':
      'La Société organisera, en collaboration avec le transporteur de son choix, les modalités de retour, dont elle informera l’Acheteur par tout moyen utile.',
    'a10-p3':
      'La Société prendra en charge les frais de retour des Produits défectueux ou non conformes. Les Produits devront être retournés à la Société dans leur emballage d’origine, accompagnés de l’ensemble de leurs accessoires et d’une copie de la facture d’achat correspondante. Les retours ne respectant pas les modalités décrites ci-dessus ne pourront pas être acceptés.',
    'a10-p4':
      "Après réception et vérification des Produits retournés, la Société proposera à l’Acheteur soit la réparation, soit le remplacement du Produit, dans la mesure du possible. Si la réparation ou le remplacement du Produit s'avèrent impossibles, la Société remboursera à l’Acheteur l’intégralité du prix payé pour le Produit, ainsi que les frais de livraison correspondants. Le remboursement sera effectué dans les meilleurs délais, et au plus tard dans les quinze (15) jours suivant la confirmation de l’impossibilité de réparer ou remplacer le Produit.",
    'a11-title': 'ARTICLE 11 - LIENS ET SITES TIERS',
    'a11-p1':
      'La Société ne pourra en aucun cas être tenue responsable de la disponibilité technique des sites internet ou des applications mobiles exploités par des tiers (y compris ses éventuels partenaires) auxquels l’Acheteur accède par l’intermédiaire du Site. La Société décline toute responsabilité concernant les contenus, publicités, produits et/ou services disponibles sur de tels sites ou applications mobiles tiers, qui sont régis par leurs propres conditions d’utilisation.',
    'a11-p2':
      'La Société n’est pas non plus responsable des transactions intervenues entre l’Acheteur et un quelconque annonceur, professionnel ou commerçant (y compris ses partenaires) vers lesquels l’Acheteur aurait été redirigé via le Site. En conséquence, la Société ne saurait en aucun cas être impliquée dans les litiges éventuels concernant notamment la livraison de produits ou services, les garanties, les déclarations ou toute autre obligation à laquelle ces tiers sont soumis.',
    'a11-p3':
      'De plus, la Société ne saurait être tenue responsable des déclarations ou communications effectuées par des tiers, tels que des partenaires commerciaux ou des promoteurs externes, relatives aux Produits ou services de la Société, lorsque ces déclarations ne reflètent pas la réalité des offres mises à disposition par la Société. L’Acheteur est invité à se référer uniquement aux informations disponibles sur le Site ou communiquées directement par la Société pour connaître les caractéristiques et conditions des Produits ou services proposés.',
    'a12-title': 'ARTICLE 12 - LOI APPLICABLE ET JURIDICTION',
    'a12-p1':
      'Les présentes conditions générales sont régies par la loi française. En cas de contestation sur la validité, l’interprétation et/ou l’exécution des présentes conditions générales, les parties conviennent que les tribunaux de Paris seront exclusivement compétents pour en juger, sauf règles de procédure impératives contraires.',
    'a13-title': 'ARTICLE 13 - ENTRÉE EN VIGUEUR',
    'a13-p1': 'Les présentes CGV sont entrées en vigueur le 06/10/2024.',
  },
  legals: {
    title: 'Mentions légales',
    'a1-title': "1. Éditeur du site et de l'application",
    'a1-p1':
      "Le site internet www.stowy.fr et l'application STOWY sont édités par :",
    'a1-p2': 'STOWY SAS',
    'a1-p3': 'Société par Actions Simplifiée au capital de 10 000 euros',
    'a1-p4':
      'Immatriculée au Registre du Commerce et des Sociétés de Chalon-sur-Saône sous le numéro 898 788 419',
    'a1-p5': 'Siège social : 50 Chemin des Caillots, 71670 Saint Firmin',
    'a1-p6': 'Adresse email : kevin [at] stowy [point] fr',
    'a1-p7': 'Directeur de la publication : Kévin Pomorski',

    'a2-title': '2. Hébergement',
    'a2-p1': 'Le site internet www.stowy.fr est hébergé par :',
    'a2-p2': 'Google Cloud Platform (Région Europe – Irlande)',
    'a2-p3':
      'Adresse : Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irlande',
    'a2-p4': 'Site web : https://cloud.google.com',
    'a2-p5': 'Téléphone : +353 1 543 1000',

    'a3-title': '3. Propriété intellectuelle',
    'a3-p1':
      "Tous les éléments présents sur le site www.stowy.fr et l'application STOWY, notamment les textes, images, photographies, vidéos, logos, graphismes, sons, bases de données, logiciels, et autres contenus, sont protégés par les lois relatives à la propriété intellectuelle et sont la propriété exclusive de STOWY SAS ou de ses partenaires.",
    'a3-p2':
      'Toute reproduction, représentation, modification, publication, transmission, dénaturation, ou exploitation totale ou partielle de ces éléments, par quelque procédé que ce soit, sans autorisation préalable et écrite de STOWY SAS, est strictement interdite et constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.',

    'a4-title': '4. Modifications',
    'a4-p1':
      "STOWY SAS se réserve le droit de modifier et d’actualiser, sans préavis, les présentes mentions légales, ainsi que tout contenu ou élément du site ou de l'application. Les utilisateurs sont invités à consulter régulièrement cette page pour être informés des éventuelles modifications.",

    'a5-title': '5. Contact',
    'a5-p1':
      'Pour toute question relative aux présentes mentions légales, vous pouvez nous contacter à l’adresse suivante : kevin [at] stowy [point] fr',
  },

  oops: {
    instructions:
      'Nous sommes navrés de ce petit désagrément, nous vous invitons à réessayer et à contacter le support <contact@stowy.fr> en cas de problème récurrent.',
  },
  thanks: {
    instructions:
      "Vous allez recevoir les informations à l'adresse email que vous avez fournie. Vous pouvez désormais fermer cette page et retourner à l'accueil.",
  },

  confidentiality: {
    title: 'Politique de Confidentialité',
    'a1-title': '1. Introduction',
    'a1-p1':
      "Cette politique de confidentialité a pour objectif d'informer les utilisateurs de l'application STOWY et du site www.stowy.fr des modalités de collecte, de traitement et de protection de leurs données personnelles, conformément au Règlement Général sur la Protection des Données (RGPD) et aux autres lois applicables en matière de protection des données.",

    'a2-title': '2. Identité et coordonnées du responsable du traitement',
    'a2-p1':
      'Le responsable du traitement des données personnelles collectées est :',
    'a2-p2': 'STOWY SAS',
    'a2-p3':
      'Société par Actions Simplifiée immatriculée au RCS de Chalon-sur-Saône sous le numéro 898 788 419',
    'a2-p4': 'Siège social : 50 Chemin des Caillots, 71670 Saint Firmin',
    'a2-p5': 'Adresse email : kevin [at] stowy [point] fr',

    'a3-title': '3. Gestion des données personnelles',
    'a3-sub1-title': '3.1 Données personnelles collectées',
    'a3-sub1-p1':
      '- Nom, Prénom, Adresse email, Adresse postale, Numéro de téléphone : Gestion du compte utilisateur, informations commerciales, facturation, respect des obligations réglementaires. Durée de conservation : 5 ans. Tiers concerné : HubSpot, Stripe.',
    'a3-sub1-p2':
      '- Données de paiement (Carte bancaire, Détail des transactions) : Achats, suivi commercial, facturation. Durée de conservation : 5 ans. Tiers concerné : HubSpot, Stripe.',
    'a3-sub1-p3':
      "- Adresse IP & User agent (paramètres de navigation, tel que la version de votre navigateur Internet): Fourniture du service, identification de l'utilisateur. Durée de conservation : 1 an. Tiers concerné : Google, Stripe, jQuery.",
    'a3-sub1-p4':
      "- Données d'utilisation (Historique des actions réalisées sur le site, historique des actions réalisées sur l'application, historique des histoires lues, interactions avec le contenu de l'application) : Statistiques, amélioration du système, support client. Durée de conservation : 1 an. Tiers concerné : Google.",

    'a3-sub2-title':
      '3.2 Partage des données avec des tiers dans le cadre de la fourniture du Service',
    'a3-sub2-p1':
      "Afin d'assurer le bon fonctionnement de notre service, garantir sa continuité, et améliorer la qualité de notre plateforme, vos données personnelles peuvent être partagées avec certains tiers. Ces partenaires agissent dans le cadre de leur rôle de fournisseurs de services essentiels, notamment pour la gestion des paiements, l'analyse des performances, et l'amélioration de l'expérience utilisateur. Voici la liste des entreprises avec lesquelles vos données peuvent être partagées :",
    'a3-sub2-p2':
      '- HubSpot Ireland Limited : 2nd Floor, One Dockland Central, Guild Street, Dublin 1, Irlande.',
    'a3-sub2-p3':
      '- Google Ireland Limited : Gordon House, Barrow Street, Dublin 4, Irlande.',
    'a3-sub2-p4':
      '- Stripe Payments Europe, Limited : The One Building, 1 Grand Canal Street Lower, Dublin 2, Irlande.',
    'a3-sub2-p5':
      '- jQuery Foundation (JS Foundation) : 1 Letterman Drive, Building D, Suite D4700, San Francisco, CA 94129, États-Unis.',

    'a3-sub3-title': "3.3 Personnages créés dans l'application",
    'a3-sub3-p1':
      "Les personnages créés dans l'application, incluant le prénom et l'apparence physique, sont considérés comme fictifs et ne peuvent en aucun cas être traités comme des données à caractère personnel selon le RGPD.",

    'a4-title': '4. Cookies et technologies similaires',
    'a4-p1':
      "Lors de la navigation sur le Site ou l'Application, des cookies peuvent être utilisés pour collecter des informations non personnelles visant à améliorer l'expérience utilisateur et à analyser l'audience.",
    'a4-p2': 'Les types de cookies utilisés incluent :',
    'a4-p3':
      "- **Cookies essentiels** : Nécessaires au bon fonctionnement de l'application (session utilisateur, gestion du consentement). De par leur caractère impératif, ces cookies ne peuvent pas être désactivés.",
    'a4-p4':
      "- **Cookies analytiques** : Utilisés pour analyser le trafic et améliorer l'application, activés uniquement après consentement.",
    'a4-p5':
      '- **Cookies publicitaires** : Utilisés pour personnaliser les annonces et mesurer leur efficacité, activés uniquement après consentement.',

    'a5-title': '5. Liste détaillée des cookies utilisés',
    'a5-p1':
      "- **Session Cookie** (Essentiel) : Maintient la session active pour l'utilisateur. Données collectées : Identifiant de session, état d'authentification. Durée : Session.",
    'a5-p2':
      "- **Consent Cookie** (Essentiel) : Enregistre les préférences de consentement de l'utilisateur. Données collectées : Préférences de consentement. Durée : 12 mois. Pas de partage avec des tiers.",
    'a5-p3':
      "- **Cookies applicatifs** (Essentiel) : Utilisé par l'application pour enregistrer la configuration de votre personnage, l'apparition du tutorial, les données d'histoire.",
    'a5-p4':
      '- **Google Analytics (_ga)** (Analytique) : Utilisé par Google Analytics pour distinguer les utilisateurs et suivre le trafic du site. Données collectées : Adresse IP, pages visitées, durée des sessions, clics. Durée : 2 ans. Partage des données avec Google Analytics.',
    'a5-p5':
      '- **Google Analytics (_gid)** (Analytique) : Utilisé par Google Analytics pour distinguer les utilisateurs à des fins statistiques. Données collectées : Adresse IP, pages visitées, durée des sessions. Durée : 24 heures. Partage des données avec Google Analytics.',
    'a5-p6':
      '- **Google Analytics (_gat)** (Analytique) : Utilisé par Google Analytics pour limiter le nombre de requêtes envoyées. Données collectées : Aucune (utilisé pour la limitation du taux de requêtes). Durée : 1 minute. Partage des données avec Google Analytics.',
    'a5-p7':
      '- **Google Ads (_gcl_au)** (Publicitaire) : Utilisé par Google Ads pour suivre les conversions des annonces. Données collectées : Identifiants uniques, interactions avec les annonces, adresse IP. Durée : 90 jours. Partage des données avec Google Ads.',

    'a6-title': '6. Consentement aux cookies et gestion des préférences',
    'a6-p1':
      'Lors de votre première visite, un bandeau de cookies vous permet de choisir les catégories de cookies que vous souhaitez activer ou désactiver.',
    'a6-p2':
      "Par défaut, seuls les cookies essentiels sont activés. Vous pouvez choisir d'activer ou de désactiver les cookies fonctionnels, analytiques et publicitaires en fonction de vos préférences.",
    'a6-p3':
      "Vous pouvez modifier ou retirer votre consentement à tout moment en accédant à notre interface de gestion des cookies, disponible en bas de page du site ou dans les paramètres de l'Application.",
    'a6-p4':
      "Si vous choisissez de désactiver certains cookies, certaines fonctionnalités de l'application peuvent ne pas fonctionner correctement.",

    'a7-title': '7. Sécurité des données',
    'a7-p1':
      "STOWY s'engage à mettre en œuvre toutes les mesures techniques et organisationnelles nécessaires pour garantir la sécurité des données personnelles collectées.",

    'a8-title': '8. Rappel des droits des utilisateurs (RGPD)',
    'a8-p1':
      "- Droit d'accès : obtenir des informations sur vos données et une copie de celles-ci.",
    'a8-p2':
      '- Droit de rectification : demander la correction des données inexactes.',
    'a8-p3': "- Droit à l'effacement : demander la suppression de vos données.",
    'a8-p4':
      '- Droit à la limitation : limiter temporairement le traitement de vos données.',
    'a8-p5': "- Droit d'opposition : s'opposer au traitement de vos données.",
    'a8-p6':
      '- Droit à la portabilité : recevoir une copie de vos données dans un format structuré.',

    'a9-title': '9. Modifications de la politique de confidentialité',
    'a9-p1':
      'STOWY se réserve le droit de modifier la présente politique de confidentialité. Les modifications seront communiquées par email, au moins un mois avant leur entrée en vigueur.',

    'a10-title': '10. Droit de réclamation',
    'a10-p1':
      'En cas de désaccord sur la manière dont vos données sont traitées, vous pouvez déposer une réclamation auprès de la CNIL.',

    'a11-title': '11. Entrée en vigueur',
    'a11-p1':
      'La présente Politique de Confidentialité est entrée en vigueur le 06/10/2024.',
  },

  global: {
    'know-more': 'En savoir plus',
    'legals-term': 'Mentions légales',
    'cgv-cgu-term': 'CGV / CGU',
    'confidentiality-term': 'Politique de Confidentialité',
    'im-interested': "Ça m'intéresse !",
    'im-with-you': 'Je suis avec vous !',
    'i-signup': "Je m'inscris",
    thanks: 'Merci de votre soutien !',
    'support-us': 'Je suis convaincu(e) !',
    oops: "Quelque chose s'est mal passé !",
    close: 'Fermer',
    continue: 'Continuer',
    connect: 'Connexion',
    subscribe: 'Inscription',
    'i-discover': 'Je découvre',
    'i-subscribe': "Je m'inscris",
    'choose-formula': 'Choisir la formule',
    'try-stowy': 'Essayer Stowy',
  },
  errors: {
    'default-title': 'Oups...',
    'invalid-email': "L'adresse email fournie semble incorrecte.",
    'invalid-firstname': 'Le prénom renseigné semble incorrect.',
    'invalid-lastname': 'Le Nom de famille renseigné semble incorrect.',
    'invalid-authentication': "Une erreur d'authentification s'est produite.",
    'invalid-payment':
      'Une erreur est survenue lors de la procédure de paiement. Veuillez réessayer.',
    'unknown-error': 'Une erreur est survenue.',
    'cgv-check': 'Veuillez accepter nos conditions générales de ventes.',
  },
  seo: {
    'page-title': 'Stowy - Histoires personnalisées pour enfants',
    'meta-description':
      'Stowy propose des histoires personnalisées pour enfants, créez des souvenirs uniques en quelques clics.',
  },
}
