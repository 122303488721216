<template>
  <div class="legals">
    <!-- Legals -->
    <div class="container page-main">
      <h1>{{ messageForKey('legals.title')}}</h1>
      <div class="content">
        <div v-for="legKey in allLegalsKeys" :key="legKey">
          <div v-if="styleForKey(legKey) === paragraphStyle">
            <p>{{ messageForKey(`${legKey}`)}}</p>
          </div>
          <div v-else-if="styleForKey(legKey) === subtitleStyle">
            <h2>{{ messageForKey(`${legKey}`)}}</h2>
          </div>
          <div v-else-if="styleForKey(legKey) === titleStyle">
            <h1>{{ messageForKey(`${legKey}`)}}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      titleStyle: "h2",
      subtitleStyle: "h2",
      paragraphStyle: "p"
    };
  },
  computed: {
    allLegalsKeys() {
      var ks = Object.keys(this.$i18n.messages.en.legals).map(k => `legals.${k}`);
      ks.shift();
      return ks;
    }
  },
  methods: {
    messageForKey(key) {
      return this.$i18n.t(key);
    },
    styleForKey(key) {
      if (typeof key !== "string") {
        return;
      }

      if (key.endsWith("subtitle")) {
        return this.subtitleStyle;
      } else if (key.endsWith("title")) {
        return this.titleStyle;
      }
      return this.paragraphStyle;
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.legals {
  background-image: url('~@common-assets/mixed-clouds-bg.png'), linear-gradient(#204B8D, #BFEAFF);
  background-size: 100%, cover;
  background-position: bottom, center;
  background-repeat: no-repeat, no-repeat;
  min-height: 100vh;
  margin: 0;
  padding: 0;

  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 36px 20px;
  }

  h1, h2 {
    color: #3b3f4a;
    font-family: "Baloo 2", sans-serif;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-top: 40px;
    line-height: 1.3;
  }

  h2 {
    font-size: 28px;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: #4a4a4a;
    margin-bottom: 20px;
    text-align: justify;
  }

  .content {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .page-main {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 36px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
}
</style>
